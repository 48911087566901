import React, { useState, useEffect } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";

import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

//Snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

//Icons
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

import moment from "moment";

//Upload
import { getAuth, signInAnonymously } from "firebase/auth";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../../firebaseConfig";

import Skeleton from "@mui/material/Skeleton";
import CircularProgress from "@mui/material/CircularProgress";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const languageList = [
  { value: "English", label: "English" },
  { value: "Hindi", label: "Hindi" },
  { value: "Bengali", label: "Bengali" },
  { value: "Japanese", label: "Japanese" },
  { value: "Korean", label: "Korean" },
];

const EditProfile = ({
  data: {
    address = "",
    city = "",
    country_id = "",
    country_phone_code = "",
    dob = "",
    email = "",
    email_verified = false,
    first_name = "",
    middle_name = "",
    last_name = "",
    gender_id = "",
    id_proof_link = "",
    id_proof_type_id = "",
    image_link = "",
    landmark = "",
    mobile = "",
    state_id = "",
    timezone = "",
    zip = "",
  } = {},
}) => {
  const [gender, setGender] = useState(0);
  const [selectedDays, setSelectedDays] = useState([]);
  const [firstName, setFirstName] = useState(first_name);
  const [middleName, setMiddleName] = useState(middle_name);
  const [lastName, setLastName] = useState(last_name);
  const [mobileNumber, setMobileNumber] = useState();

  const [addressDetails, setAddressDetails] = useState(address);
  const [landmarkDetails, setLandmarkDetails] = useState(landmark);
  const [cityName, setCityName] = useState(city);
  const [stateID, setStateID] = useState(0);
  const [zipCode, setZipCode] = useState(0);

  const [countryList, setCountryList] = useState([]);
  const [proofIDList, setproofIDList] = useState([]);
  const [proofName, setProofName] = useState();

  const [stateList, setStateList] = useState([]);

  const [countryCallingCode, setCountryCallingCode] = useState("");

  const [profilePicture, setProfilePicture] = useState(image_link);
  //Snackbar
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [selectedFiles, setSelectedFiles] = useState([]);

  const defaultImage = "/assets/img/default-profile.jpg";

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  const userID = JSON.parse(localStorage.getItem("profile")).UserId;

  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);

  //When values are poplulated
  useEffect(() => {
    if (first_name) {
      setFirstName(first_name);
      setMiddleName(middle_name);
      setLastName(last_name);
      setAddressDetails(address);
      setProfilePicture(image_link);
      setMobileNumber(mobile);
      setStateID(state_id);
      setCityName(city);
      setZipCode(zip);
      setLandmarkDetails(landmark);
      setIsLoading(false);
    }
  }, [first_name]);

  //Countries List
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch("https://us.uwc.world/new-uwc/country", {
          method: "GET",
          headers,
        });
        const postResponse = await response.json();
        if (postResponse.code === 200) {
          setCountryList(postResponse.data.countries);
          fetchStates(postResponse.data.countries[0].code);
          fetchCountryCallingCode(postResponse.data.countries[0].code);
        } else {
          fetchStates("US");
          fetchCountryCallingCode("US");
        }
        // Handle the response data
      } catch (error) {
        console.error(error);
        // Handle the error
      }
    };

    fetchCountries();
  }, []);

  //ID Proof
  useEffect(() => {
    const fetchIDs = async () => {
      try {
        const response = await fetch(
          `https://us.uwc.world/new-uwc/registration/id-proofs`,
          {
            method: "GET",
            headers,
          }
        );

        const postResponse = await response.json();

        setproofIDList(postResponse?.data.id_proofs);
      } catch (error) {
        console.error(error);
        // Handle the error
      }
    };

    fetchIDs();
  }, []);

  useEffect(() => {
    const proofItem = proofIDList?.filter(
      (item) => item.id == id_proof_type_id
    );
    setProofName(proofItem[0]?.name);
  }, [proofIDList]);

  //Firebase login
  useEffect(() => {
    const auth = getAuth();
    signInAnonymously(auth)
      .then(() => {
        // Signed in..
        console.log("SIGNED IN");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        //console.log("errorMessage",errorMessage)
        // ...
      });
  }, []);
  const handleSelectlanguages = (event) => {
    setSelectedDays(event.target.value);
  };

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  //Profile Picture
  const handleChangeProfilePic = async (event) => {
    try {
      let file = Array.from(event.target.files)[0];

      if (
        file?.type === "image/png" ||
        file?.type === "image/jpg" ||
        file?.type === "image/jpeg"
      ) {
        const dirName = moment().format("MMMM_YYYY");
        const storageRef = ref(storage, `${dirName}/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            //console.log(`Upload progress: ${percent}%`);
          },
          (error) => {
            console.error("Upload error:", error);
            // Handle upload error if needed
          },
          async () => {
            try {
              const url = await getDownloadURL(uploadTask.snapshot.ref);

              setProfilePicture(url);
            } catch (error) {
              console.error("Error getting download URL:", error);
              // Handle error if needed
            }
          }
        );
      } else {
        console.error("Incorrect file format.");
      }
    } catch (error) {
      console.error("Error handling file upload:", error);
      // Handle error if needed
    }
  };

  const handleDeleteProfilePic = () => {
    setProfilePicture(defaultImage);
  };
  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };
  const handleMiddleNameChange = (e) => {
    setMiddleName(e.target.value);
  };
  const handleLasttNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleAddress = (e) => {
    setAddressDetails(e.target.value);
  };

  const handleMobileChange = (e) => {
    //setMobileNumber(`${countryCallingCode} ${e.target.value}`)

    const { value } = e.target;
    const isBackspace = e.nativeEvent.inputType === "deleteContentBackward";

    if (isBackspace) {
      setMobileNumber(value);
    } else {
      setMobileNumber(`${value}`);
    }
  };
  //console.log("selectedFiles", selectedFiles);

  const handleStateChange = (e) => {
    setStateID(e.target.value);
  };

  const handleCityChange = (e) => {
    setCityName(e.target.value);
  };

  const handleZipChange = (e) => {
    setZipCode(e.target.value);
  };

  const handleLandmarkChange = (e) => {
    setLandmarkDetails(e.target.value);
  };

  // Fetch Country Calling Code

  const fetchCountryCallingCode = async (countryCode) => {
    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/country/call-code/${countryCode}`,
        {
          method: "GET",
          headers,
        }
      );

      const postResponse = await response.json();
      const phoneCode = await postResponse?.data?.phone_code;

      //console.log("postResponse CCC", phoneCode);
      setCountryCallingCode(phoneCode);
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };

  // Fetch State list based on country
  const fetchStates = async (countryCode) => {
    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/state/${countryCode}`,
        {
          method: "GET",
          headers,
        }
      );

      const postResponse = await response.json();
      const statesArray = await postResponse.data?.states;

      setStateList(statesArray);
      // Handle the response data
    } catch (error) {
      console.error(error);
      // Handle the error
    }
  };

  const handleUpdateProfile = async () => {
    setIsUpdating(true);
    const payload = {
      uwc_user_id: userID,
      email: email,
      mobile: mobileNumber,
      image_link: profilePicture == defaultImage ? "" : profilePicture,
      address: addressDetails,
      landmark: landmarkDetails,
      state_id: stateID,
      city: cityName,
      zip: zipCode,
      country_phone_code: countryCallingCode,
    };

    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/expert/dashboard/update-profile-detail`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      if (postResponse.code == 200) {
        setSnackbarMessage(postResponse?.data?.message);
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsUpdating(false);
    }
  };

  //console.log("stateID", stateID)
  return (
    <>
      <div className="Content--Section">
        <div className="mt-4 d-flex align-items-center gap-3">
          {isLoading ? (
            <>
              <Skeleton
                variant="rectangular"
                width={120}
                height={120}
                sx={{ borderRadius: "20px" }}
              />
              <div>
                <Skeleton
                  variant="rectangular"
                  width={200}
                  height={40}
                  sx={{ borderRadius: "5px", mb: 2 }}
                />
                <Skeleton
                  variant="rectangular"
                  width={200}
                  height={40}
                  sx={{ borderRadius: "5px" }}
                />
              </div>
            </>
          ) : (
            <>
              <img
                src={profilePicture}
                width={120}
                height={120}
                style={{ borderRadius: "20px", objectFit: "cover" }}
              />

              <div>
                <Button
                  component="label"
                  htmlFor="profile-pic-upload"
                  sx={{
                    backgroundColor: "#F5F5F5",
                    color: "#414141",
                    borderRadius: "5px",
                    padding: "10px 20px",
                    textTransform: "capitalize",
                    fontWeight: "600",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    width: "200px",
                  }}
                >
                  <input
                    id="profile-pic-upload"
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleChangeProfilePic}
                  />
                  <ImageOutlinedIcon />
                  Change Profile Pic
                </Button>
                <Button
                  onClick={handleDeleteProfilePic}
                  sx={{
                    backgroundColor: "#D2293D1A",
                    color: "#D2293D",
                    borderRadius: "5px",
                    padding: "10px 20px",
                    textTransform: "capitalize",
                    fontWeight: "600",
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    width: "200px",
                    marginTop: "20px",
                  }}
                >
                  <DeleteOutlineOutlinedIcon />
                  Delete Profile Pic
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="Content--Section">
        <h6>Personal Details</h6>
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          {isLoading ? (
            <>
              {[...Array(9)].map((_, index) => (
                <Grid item xs={4} key={index}>
                  <Skeleton
                    variant="rectangular"
                    height={56}
                    sx={{ borderRadius: "4px" }}
                  />
                </Grid>
              ))}
            </>
          ) : (
            <>
              <Grid item xs={4}>
                <TextField
                  id="firstname"
                  label="First Name"
                  value={firstName}
                  //onChange={handleFirstNameChange}
                  variant="outlined"
                  fullWidth
                  //defaultValue={""}
                  disabled
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  id="middlename"
                  label="Middle Name (Optional)"
                  variant="outlined"
                  fullWidth
                  value={middleName}
                  //onChange={handleMiddleNameChange}
                  //defaultValue={middle_name}
                  disabled
                />
              </Grid>

              <Grid item xs={4}>
                <TextField
                  id="lastname"
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  //defaultValue={last_name}
                  //onChange={handleLasttNameChange}
                  value={lastName}
                  disabled
                />
              </Grid>

              {/* Gender */}
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="gender-select-label">Gender</InputLabel>
                  <Select
                    fullWidth
                    labelId="gender-select-label"
                    id="gender-select"
                    value={gender_id}
                    label="Gender"
                    //onChange={handleGenderChange}
                    disabled
                  >
                    <MenuItem value={0}>Male</MenuItem>
                    <MenuItem value={1}>Female</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Email */}
              <Grid item xs={4}>
                <TextField
                  id="email"
                  label=""
                  value={email}
                  variant="outlined"
                  fullWidth
                  disabled
                  //defaultValue={""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {email_verified && (
                          <DoneOutlinedIcon sx={{ color: "#55A51D" }} />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              {/* DOB */}
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={dayjs(dob)}
                    label="Date of birth"
                    sx={{ width: "100%", paddingTop: "0" }}
                    disabled
                  />
                </LocalizationProvider>
              </Grid>

              {/* Language */}
              {/* <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel sx={{ background: "#fff" }}>Language</InputLabel>
                  <Select
                    fullWidth
                    multiple
                    value={selectedDays}
                    label="Language"
                    onChange={handleSelectlanguages}
                    renderValue={(selected) => selected.join(", ")}
                  >
                    {languageList.map((day) => (
                      <MenuItem key={day.value} value={day.value}>
                        <Checkbox
                          checked={selectedDays.includes(day.value)}
                          name={day.label}
                        />
                        {day.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid> */}
            </>
          )}
        </Grid>
      </div>

      {/* ID Proof */}
      <div className="Content--Section">
        <h6>ID Proof Submitted</h6>
        {isLoading ? (
          <Skeleton
            variant="rectangular"
            height={56}
            sx={{ borderRadius: "4px", mt: 2 }}
          />
        ) : (
          <div className="UploadedFiles--Section">
            <TextField
              disabled
              fullWidth
              id="uploaded-basic"
              variant="outlined"
              value={proofName}
              className="mt-2"
            />
            {/* <div className="UploadedItem">
              <img src={id_proof_link || defaultImage} width={100} height={100} />
              <p>{proofName}</p>
            </div> */}
          </div>
        )}
      </div>

      <div className="Content--Section">
        <h6>Address Details</h6>

        {/* Address */}
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          {isLoading ? (
            <>
              {[...Array(9)].map((_, index) => (
                <Grid item xs={4} key={index}>
                  <Skeleton
                    variant="rectangular"
                    height={56}
                    sx={{ borderRadius: "4px" }}
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <Skeleton
                  variant="rectangular"
                  width={120}
                  height={40}
                  sx={{ borderRadius: "4px", ml: "auto" }}
                />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={4}>
                <TextField
                  id="address"
                  label="Address"
                  variant="outlined"
                  fullWidth
                  value={addressDetails}
                  onChange={handleAddress}
                  //defaultValue={""}
                />
              </Grid>

              {/* Landmark */}
              <Grid item xs={4}>
                <TextField
                  id="landmark"
                  label="Landmark (Optional)"
                  variant="outlined"
                  value={landmarkDetails || ""}
                  onChange={handleLandmarkChange}
                  fullWidth
                />
              </Grid>

              {/* Country */}
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="country-select-label">Country</InputLabel>
                  <Select
                    fullWidth
                    labelId="country-select-label"
                    id="country-select"
                    value={country_id || ""}
                    label="country"
                    //onChange={handleCountryChange}
                    disabled
                  >
                    {countryList?.map((country) => {
                      return (
                        <MenuItem key={country.code} value={country.id}>
                          {country.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>

              {/* State */}
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <InputLabel id="state-select-label">State</InputLabel>
                  <Select
                    fullWidth
                    labelId="state-select-label"
                    id="state-select"
                    value={stateID || ""}
                    label="state"
                    onChange={handleStateChange}
                    disabled={isUpdating}
                  >
                    {stateList?.map((item) => (
                      <MenuItem key={item.code} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* City */}
              <Grid item xs={4}>
                <TextField
                  id="city"
                  label="City"
                  value={cityName}
                  onChange={handleCityChange}
                  defaultValue=""
                  variant="outlined"
                  fullWidth
                />
              </Grid>

              {/* Timezone */}
              <Grid item xs={4}>
                <TextField
                  id="timezone"
                  label="Timezone"
                  value={timezone}
                  defaultValue=""
                  variant="outlined"
                  fullWidth
                  disabled
                />
              </Grid>

              {/* Zipcode */}
              <Grid item xs={4}>
                <TextField
                  id="zipcode"
                  label="Zip Code"
                  value={zipCode}
                  onChange={handleZipChange}
                  defaultValue=""
                  variant="outlined"
                  inputProps={{ inputMode: "numeric" }}
                  fullWidth
                />
              </Grid>

              {/* Mobile */}
              <Grid item xs={4}>
                <TextField
                  id="mobile"
                  label="Mobile (Optional)"
                  variant="outlined"
                  value={mobileNumber}
                  defaultValue={""}
                  onChange={handleMobileChange}
                  InputProps={{
                    inputMode: "numeric",
                    startAdornment: (
                      <InputAdornment position="start">
                        {countryCallingCode || "+1"}
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Grid>

              {/* <Grid item xs={4}>
                <TextField
                  id="officephone"
                  label="Office Phone Number (Optional)"
                  variant="outlined"
                  fullWidth
                />
              </Grid> */}
            </>
          )}
        </Grid>

        <Button
          variant="contained"
          disableElevation
          onClick={handleUpdateProfile}
          className="PrimaryCTAExp"
          disabled={isUpdating}
          sx={{ marginLeft: "auto", display: "block", marginTop: "30px" }}
        >
          {isUpdating ? (
            <div className="d-flex align-items-center gap-2">
              <CircularProgress size={24} sx={{ color: "white" }} />
              <span>Updating</span>
            </div>
          ) : (
            "Update Profile"
          )}
        </Button>
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default EditProfile;
