import React, { useState, useEffect, useRef } from "react";
import { ButtonGroup, Col, Form, Row } from "react-bootstrap";
import "./RegisterAsProfessional.css";
import {
  Autocomplete,
  MenuItem,
  Stack,
  TextField,
  Checkbox,
  LinearProgress,
  Button,
  Chip,
  CircularProgress,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import dayjs from "dayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  usOnBoardingExpertProfessionalDetails,
  usOnBoardingExpert_RegisterAs,
  usOnBoarding_Area_Interest,
  usOnBoarding_Languages,
  usOnBoarding_LicenseState,
  usOnBoarding_LicenseType,
  usOnBoarding_MentalDisorderTypeList,
  usOnBoarding_TreatmentList,
} from "../core/_request";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import moment from "moment/moment";
import { storage } from "../../firebaseConfig";
import { ToastContainer } from "react-toastify";
import UploadFileRoundedIcon from "@mui/icons-material/UploadFileRounded";
import CloseIcon from "@mui/icons-material/Close";
import { enqueueSnackbar } from "notistack";
import {
  getInsuranceNetworkList,
  getTaxonomyCodesList,
} from "../core/_request";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const RegisterAsProfessionalProfessionalDetailTab = (props) => {
  const trainingCertificate = useRef();
  const cvRef = useRef();
  const boardCertificateRef = useRef();
  const licenseDocRefs = useRef({});
  const additionalCertificatesRef = useRef();

  const [data, setData] = useState({});
  const [loading, setLoading] = useState();
  const [registerAsList, setRegisterAsList] = useState([]);
  const [area_interestList, setArea_InterestList] = useState([]);
  const [languagesList, setLanguagesList] = useState([]);
  const [licenseStateList, setLicenseStateList] = useState([]);
  const [licenseTypeList, setLicenseTypeList] = useState([]);
  const [treatmentList, setTreatmentList] = useState([]);
  const [mentalDisorderList, setMentalDisorderList] = useState([]);
  const [schoolOrTrainingDocumentPercent, setSchoolOrTrainingDocumentPercent] =
    useState();
  const [schoolOrTrainingDocumentLoading, setSchoolOrTrainingDocumentLoading] =
    useState();
  const [cvFilePercent, setCVFilePercent] = useState();
  const [cVFileLoading, setCVFileLoading] = useState();
  const [boardCertificationPercent, setBoardCertificationPercent] = useState();
  const [boardCertificationLoading, setBoardCertificationLoading] = useState();
  const [licenseFilePercent, setLicenseFilePercent] = useState({});
  const [licenseFileLoading, setLicenseFileLoading] = useState({});

  const [additionalCertificatePercent, setAdditionalCertificatePercent] =
    useState();
  const [additionalCertificateLoading, setAdditionalCertificateLoading] =
    useState(false);
  const [license_type, setlicense_type] = useState(false);
  const [isMentalDisorders, setIsMentalDisorders] = useState(true);
  const ALPHA_NUMERIC_DASH_REGEX = /^[a-zA-Z ]+$/;
  const ALPHANUMERIC_DASH_REGEX = /^[0-9a-zA-Z.]*$/;
  const currentYear = new Date().getFullYear();

  const [trainingCertificateDocDetail, setTrainingCertificateDocDetail] =
    useState();

  const [cvDocDetail, setCvDocDetail] = useState();

  const [boardCertificateDocDetail, setBoardCertificateDocDetail] = useState();

  const [licenseDocDetail, setLicenseDocDetail] = useState();

  const [additionalCertificatesDetail, setAdditionalCertificateDetail] =
    useState([]);
  const [uploadProgress, setUploadProgress] = useState([]);
  const [networkList, setNetworkList] = useState([]);
  const [taxonomyCodes, setTaxonomyCodes] = useState([]);
  const [licenseFileNames, setLicenseFileNames] = useState({});

  const booleanOptions = [
    { value: "YES", label: "Yes" },
    { value: "NO", label: "No" },
  ];
  const yearsOfExp = Array.from({ length: 51 }, (_, i) => i);

  const years = Array.from(new Array(70), (val, index) => currentYear - index); // Generate an array of past 50 years

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    var userId =
      JSON.parse(sessionStorage.getItem("UsOnBoarding"))?.id ??
      JSON.parse(sessionStorage.getItem("User_Registered_Info"))?.id;
    var body = { user_id: userId };
    usOnBoardingExpert_RegisterAs().then((resp) =>
      setRegisterAsList(resp?.data?.register_as)
    );
    usOnBoarding_Languages().then((resp) =>
      setLanguagesList(resp?.data?.languages)
    );
    usOnBoarding_LicenseState(body).then((resp) =>
      setLicenseStateList(resp?.data?.states)
    );
    usOnBoarding_LicenseType().then((resp) =>
      setLicenseTypeList(resp?.data?.license_type)
    );
    usOnBoarding_TreatmentList().then((resp) =>
      setTreatmentList(resp?.data?.treatments)
    );
    usOnBoarding_MentalDisorderTypeList().then((resp) =>
      setMentalDisorderList(resp?.data?.mental_disorders)
    );
    getInsuranceNetworkList().then(
      (resp) => setNetworkList(resp?.insurance_networks) // Accessing insurance_networks
    );
    getTaxonomyCodesList().then(
      (resp) => setTaxonomyCodes(resp?.taxonomy_codes) // Adjust if your taxonomy response is similar
    );
  }, []);

  useEffect(() => {
    setData({
      ...data,
      areaOfInterest: area_interestList?.filter((p) =>
        data?.areaOfInterest_2?.find((x) => x === p?.id)
      ),
    });
  }, [area_interestList]);

  useEffect(() => {
    if (data?.register_as_id) {
      setData((prevData) => ({
        ...prevData,
        part_of_insurance_network: "",
      }));
    }
  }, [data?.register_as_id]);

  const handleYearChange = (event) => {
    setData({ ...data, graduation_year: event.target.value });
    // setSelectedYear(event.target.value);
  };

  const onRegisterAsChange = (value) => {
    var sData = JSON.parse(
      sessionStorage.getItem("User_Registered_Info")
    )?.professional;
    var setdatavalue = {
      user_id: sData?.user_id,
      register_as_id: sData?.register_as_id,
      school: sData?.school,
      graduation_year: sData?.graduation_year,
      CVFile: sData?.resume,
      BoardCertification: sData?.board_certification,
      licensedStates: sData?.licensedStates,
      treatment_ids: treatmentList?.filter((p) =>
        sData?.treatment_ids?.find((x) => x === p?.id)
      ),
      mental_disorders_id: sData?.mental_disorders_ids,
      mental_disorder: sData?.mental_disorder,
      AdditionalCertificate: sData?.additional_certificates,
      NpiNumber: sData?.npi_number,
      about_experience: sData?.about_experience,
      languages: languagesList?.filter((p) =>
        sData?.language_ids?.find((x) => x === p?.id)
      ),
      experience: sData?.experience,
      schoolOrTrainingDocument: sData?.documents,
      areaOfInterest_2: sData?.area_of_interest_ids,
      fein: sData?.feinNumber,
      part_of_insurance_network: sData?.part_of_insurance_network,
      insurance_network_ids: sData?.insurance_network_ids,
      taxonomy_code_ids: sData?.taxonomy_code_ids,
    };
    var item = registerAsList?.find((o) => o.id == value);

    if (item) {
      var Area_Interest_body = {
        register_as_id: value,
      };
      usOnBoarding_Area_Interest(Area_Interest_body).then((resp) => {
        setArea_InterestList(resp?.data?.area_of_interests);
        setData({
          ...setdatavalue,
          Id: value,
          RegisterAs: item.name,
          Register_fields: JSON.parse(JSON.stringify(item?.fields)),
          areaOfInterest: area_interestList?.filter((p) =>
            resp?.data?.area_of_interests?.area_of_interest_ids?.find(
              (x) => x === p?.id
            )
          ),
        });
      });
    } else {
      setData(setdatavalue);
    }
  };

  const onTreamentChange = (event, values) => {
    setData({ ...data, treatment_ids: values });
  };

  const onMentalDisordersChange = (value) => {
    var DisorderList = mentalDisorderList.filter((o) => o.id === value);
    if (DisorderList[0].name === "Other") {
      setIsMentalDisorders(false);
    } else {
      setIsMentalDisorders(true);
    }
    setData({ ...data, mental_disorders_id: value });
  };

  const handleFileUpload = async (event) => {
    setSchoolOrTrainingDocumentLoading(true); // Show loader

    const file = event.target.files[0];

    // Check file size
    if (file.size > 5e6) {
      enqueueSnackbar("File Size Exceeds.", { variant: "warning" });
      setSchoolOrTrainingDocumentLoading(false);
      return false;
    }

    // Check file format
    if (
      file?.type === "image/png" ||
      file?.type === "image/jpg" ||
      file?.type === "image/jpeg" ||
      file?.type === "application/pdf"
    ) {
      setTrainingCertificateDocDetail(file);

      // Upload the file
      const dirName = moment().format("MMMM_YYYY");
      const storageRef = ref(storage, dirName + "/" + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setSchoolOrTrainingDocumentPercent(percent);
        },
        (err) => {
          enqueueSnackbar(err, { variant: "error" });
          setSchoolOrTrainingDocumentLoading(false);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            setData({ ...data, schoolOrTrainingDocument: url });
            setSchoolOrTrainingDocumentLoading(false);
            enqueueSnackbar("File uploaded successfully.", {
              variant: "success",
            });
          });
        }
      );
    } else {
      // Incorrect file format
      enqueueSnackbar("Incorrect file format.", { variant: "error" });
      setSchoolOrTrainingDocumentLoading(false);
    }
  };

  const handleClearButtonClick = () => {
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
    setData({ ...data, schoolOrTrainingDocument: null });
  };

  const handleCVFileUpload = async (event) => {
    setCVFileLoading(true);

    if (event.target.files[0].size > 5e6) {
      enqueueSnackbar("File Size Exceeds.", { variant: "warning" });
      setCVFileLoading(false);
      return false;
    } else {
      var file = event.target.files[0];
      setCvDocDetail(file);
      if (file?.type === "application/pdf") {
        var dirName = moment().format("MMMM_YYYY");
        const storageRef = ref(storage, dirName + "/" + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            setCVFilePercent(percent);
          },
          (err) => enqueueSnackbar(err, { variant: "error" }),
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              enqueueSnackbar("File uploaded Successfully.", {
                variant: "success",
              });
              setData({ ...data, CVFile: url });
              setCVFileLoading(false);
            });
          }
        );
      } else {
        enqueueSnackbar("Incorrect  file format.", { variant: "error" });
      }
    }
  };

  const handleBoardCertificationFileUpload = async (event) => {
    setBoardCertificationLoading(true);

    const file = event.target.files[0];

    if (file.size > 5e6) {
      enqueueSnackbar("File size exceeds 5MB.", { variant: "warning" });
      setBoardCertificationLoading(false);
      return;
    }

    if (
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg" ||
      file.type === "application/pdf"
    ) {
      setBoardCertificateDocDetail(file); // Set the file after verification
      const dirName = moment().format("MMMM_YYYY");
      const storageRef = ref(storage, `${dirName}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setBoardCertificationPercent(percent);
        },
        (err) => enqueueSnackbar(err.message, { variant: "error" }),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            enqueueSnackbar("File uploaded successfully.", {
              variant: "success",
            });
            setData((prev) => ({ ...prev, BoardCertification: url }));
            setBoardCertificationLoading(false);
          });
        }
      );
    } else {
      setBoardCertificationLoading(false);
      enqueueSnackbar("Incorrect file format.", { variant: "error" });
    }
  };

  const handleAdditionalCertificateFileUpload = async (event) => {
    setAdditionalCertificateLoading(true); // Make sure loading is true when uploading starts

    const files = event.target.files;
    let uploadProgressArray = [...uploadProgress];
    let certificatesArray = [...additionalCertificatesDetail];

    for (let i = 0; i < files?.length; i++) {
      const file = files[i];

      if (file.size > 5e6) {
        enqueueSnackbar("File Size Exceeds.", { variant: "warning" });
        setAdditionalCertificateLoading(false); // Reset the loading state
        return false;
      }

      if (
        file.type === "image/png" ||
        file.type === "image/jpg" ||
        file.type === "image/jpeg" ||
        file.type === "application/pdf"
      ) {
        const dirName = moment().format("MMMM_YYYY");
        const storageRef = ref(storage, dirName + "/" + file.name);
        const uploadTask = uploadBytesResumable(storageRef, file);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
            uploadProgressArray[i] = percent;
            setUploadProgress([...uploadProgressArray]);
            console.log(`Upload Progress for ${file.name}: ${percent}%`); // Debug log
          },
          (err) => {
            enqueueSnackbar(err.message, { variant: "error" });
            setAdditionalCertificateLoading(false); // Ensure loading is turned off on error
          },
          async () => {
            const url = await getDownloadURL(uploadTask.snapshot.ref);
            enqueueSnackbar("File uploaded Successfully.", {
              variant: "success",
            });
            setData((prev) => ({ ...prev, BoardCertification: url }));

            certificatesArray.push({ fileName: file.name, fileUrl: url });
            setAdditionalCertificateDetail([...certificatesArray]);

            // Ensure progress is updated for all files
            setUploadProgress([...uploadProgressArray]);

            // Stop loader after upload completes
            if (i === files.length - 1) {
              setAdditionalCertificateLoading(false);
            }
          }
        );
      } else {
        enqueueSnackbar("Incorrect file format.", { variant: "error" });
        setAdditionalCertificateLoading(false); // Ensure loading is turned off for incorrect format
      }
    }
  };

  // console.log("data professional", data);

  const onClickProfileData = () => {
    setIsSubmitting(true); // Start loading

    if (data?.RegisterAs === "Mental Health and Wellness Coach") {
      if (data?.languages?.length === 0) {
        enqueueSnackbar("Required Languages.", { variant: "warning" });
        setIsSubmitting(false);
        return false;
      } else if (
        data?.experience === undefined ||
        data?.experience === null ||
        data?.experience === ""
      ) {
        enqueueSnackbar("Required Experience.", { variant: "warning" });
        setIsSubmitting(false);
        return false;
      } else if (data?.areaOfInterest?.length === 0) {
        enqueueSnackbar("Required Specialization.", { variant: "warning" });
        setIsSubmitting(false);
        return false;
      } else if (
        data?.about_experience === undefined ||
        data?.about_experience === null ||
        data?.about_experience === ""
      ) {
        enqueueSnackbar("Required Professional Experience", {
          variant: "warning",
        });
        setIsSubmitting(false);
        return false;
      } else if (data?.about_experience?.length > 2000) {
        enqueueSnackbar("Please enter experience under 250 words.", {
          variant: "warning",
        });
        setIsSubmitting(false);
        return false;
      } else {
        const user_id =
          JSON.parse(sessionStorage.getItem("UsOnBoarding"))?.id ??
          JSON.parse(sessionStorage.getItem("User_Registered_Info"))?.id;

        const body = {
          user_id: user_id,
          register_as_id: data?.Id,
          language_ids: data?.languages?.map((o) => o?.id),
          experience: data?.experience,
          documents: [data?.schoolOrTrainingDocument ?? ""],
          area_of_interest_ids:
            data?.areaOfInterest?.map((o) => o?.id) ?? data?.areaOfInterest,
          about_experience: data?.about_experience,
        };

        usOnBoardingExpertProfessionalDetails(body).then((resp) => {
          setIsSubmitting(false); // Stop loading
          if (resp?.code === 200) {
            enqueueSnackbar("Professional Details Saved Successfully", {
              variant: "success",
            });
            sessionStorage.setItem("UsOnBoarding", JSON.stringify(resp?.data));
            sessionStorage.setItem("RegisterAs", data?.Id);
            props.onNext();
            props.onSuccess();
          } else {
            enqueueSnackbar(resp?.data?.error ?? resp?.message, {
              variant: "error",
            });
          }
        }).catch(() => {
          setIsSubmitting(false); // Stop loading on error
        });
      }
    } else if (data?.RegisterAs === "Therapist") {
      if (data?.languages?.length === 0) {
        enqueueSnackbar("Required Languages", { variant: "warning" });
        setIsSubmitting(false);
        return false;
      } else if (
        data?.experience === undefined ||
        data?.experience === null ||
        data?.experience === ""
      ) {
        enqueueSnackbar("Required Experience", { variant: "warning" });
        setIsSubmitting(false);
        return false;
      } else if (
        data?.school === undefined ||
        data?.school === null ||
        data?.school === ""
      ) {
        enqueueSnackbar("Required School", { variant: "warning" });
        setIsSubmitting(false);
        return false;
      } else if (
        data?.graduation_year === undefined ||
        data?.graduation_year === null ||
        data?.graduation_year === ""
      ) {
        enqueueSnackbar("Required Graduation Year", { variant: "warning" });
        setIsSubmitting(false);
        return false;
      } else if (data?.graduation_year > currentYear) {
        enqueueSnackbar(
          "Graduation year cannot be greater than the current year",
          { variant: "warning" }
        );
        setIsSubmitting(false);
        return false;
      } else if (
        data?.NpiNumber === undefined ||
        data?.NpiNumber === null ||
        data?.NpiNumber === ""
      ) {
        enqueueSnackbar("Required NPI Number", { variant: "warning" });
        setIsSubmitting(false);
        return false;
      } else if (data?.NpiNumber?.length < 10) {
        enqueueSnackbar("Required valid NPI Number", { variant: "warning" });
        setIsSubmitting(false);
        return false;
      } else if (
        data?.CVFile === undefined ||
        data?.CVFile === null ||
        data?.CVFile === ""
      ) {
        enqueueSnackbar("Required CV/Resume", { variant: "warning" });
        setIsSubmitting(false);
        return false;
      } else if (
        data?.qualification === undefined ||
        data?.qualification === null ||
        data?.qualification === ""
      ) {
        enqueueSnackbar("Required Qualification", { variant: "warning" });
        setIsSubmitting(false);
        return false;
      } else if (
        data?.BoardCertification === undefined ||
        data?.BoardCertification === null ||
        data?.BoardCertification === ""
      ) {
        enqueueSnackbar("Required Qualification Transcript", { variant: "warning" });
        setIsSubmitting(false);
        return false;
      } else if (
        data?.licensedStates === undefined ||
        data?.licensedStates === null ||
        data?.licensedStates === ""
      ) {
        enqueueSnackbar(
          "Please Choose states where you are licensed to practice",
          { variant: "warning" }
        );
        setIsSubmitting(false);
        return false;
      } else if (
        data?.licensedStates.some((state) => {
          if (
            !state.license_type_id ||
            !state.license_expiry_date ||
            !state.license ||
            !state.license_number
          ) {
            return true;
          }
          if (state.license_type_id === 7 && state.license_type === "") {
            return true;
          }
          return false;
        })
      ) {
        enqueueSnackbar(
          "All licensed states must have License Type, Expiration Date, License Document, and 'Others' field (if applicable)",
          { variant: "warning" }
        );
        setIsSubmitting(false);
        return false;
      } else if (data?.treatment_ids.length === 0) {
        enqueueSnackbar("Required What Do You Treat?", { variant: "warning" });
        setIsSubmitting(false);
        return false;
      } else if (
        data?.about_experience === undefined ||
        data?.about_experience === null ||
        data?.about_experience === ""
      ) {
        enqueueSnackbar("Required Professional Experience and Specialization", {
          variant: "warning",
        });
        setIsSubmitting(false);
        return false;
      } else if (data?.about_experience?.length > 2000) {
        enqueueSnackbar("Please enter experience under 250 words", {
          variant: "warning",
        });
        setIsSubmitting(false);
        return false;
      } else if (
        data?.part_of_insurance_network === undefined ||
        data?.part_of_insurance_network === null ||
        data?.part_of_insurance_network === ""
      ) {
        enqueueSnackbar(
          "Please choose if you are a part of the insurance network",
          { variant: "warning" }
        );
        setIsSubmitting(false);
        return false;
      } else if (data?.part_of_insurance_network === "YES") {
        if (!data?.insurance_network_ids?.length) {
          enqueueSnackbar("Required Insurance Networks", {
            variant: "warning",
          });
          setIsSubmitting(false);
          return false;
        }
        if (!data?.taxonomy_code_ids?.length) {
          enqueueSnackbar("Required Taxonomy", { variant: "warning" });
          setIsSubmitting(false);
          return false;
        }
      }

      const user_id =
        JSON.parse(sessionStorage.getItem("UsOnBoarding"))?.id ??
        JSON.parse(sessionStorage.getItem("User_Registered_Info"))?.id;

      const vbody = {
        user_id: user_id,
        register_as_id: data?.Id,
        school: data?.school,
        qualification: data?.qualification,
        graduation_year: data?.graduation_year,
        resume: data?.CVFile,
        experience: data?.experience,
        language_ids: data?.languages?.map((o) => o?.id),
        board_certification: data?.BoardCertification,
        license_ids: data?.licensedStates,
        npi_number: data?.NpiNumber,
        about_experience: data?.about_experience,
        part_of_insurance_network: data?.part_of_insurance_network,
        insurance_network_ids: data?.insurance_network_ids,
        taxonomy_code_ids: data?.taxonomy_code_ids,
        treatment_ids: data?.treatment_ids,
      };

      usOnBoardingExpertProfessionalDetails(vbody).then((resp) => {
        setIsSubmitting(false); // Stop loading
        if (resp?.code === 200) {
          enqueueSnackbar("Professional Details Saved Successfully", {
            variant: "success",
          });
          sessionStorage.setItem("UsOnBoarding", JSON.stringify(resp?.data));
          sessionStorage.setItem("RegisterAs", data?.Id);
          props.onNext();
          props.onSuccess();
        } else {
          enqueueSnackbar(resp?.data?.error ?? resp?.message, {
            variant: "error",
          });
        }
      }).catch(() => {
        setIsSubmitting(false); // Stop loading on error
      });
    } else {
      enqueueSnackbar("Please select your profession.", { variant: "warning" });
      setIsSubmitting(false);
    }
  };

  // console.log("data professional", data);

  const onChangeAutoAreaOfInterest = (event, values) => {
    setData({ ...data, areaOfInterest: values });
  };

  const handleStateChange = (event, newValue) => {
    // Add new states to the licensedStates array, without overriding existing state data
    const updatedStates = newValue.map((selectedState) => {
      // Check if this state is already in the licensedStates array
      const existingState = data.licensedStates?.find(
        (state) => state.state_id === selectedState.id
      );

      return (
        existingState || {
          state_id: selectedState.id,
          license_expiry_date: null,
          license_type_id: "",
          license_type: "",
          license: null,
          license_number: "",
        }
      );
    });

    setData({ ...data, licensedStates: updatedStates });
  };

  const onLicenseTypeChange = (value, state_id) => {
    // Handle "Other" license type option
    const isOtherLicenseType =
      licenseTypeList.find((o) => o.id === value)?.name === "Other";

    const updatedLicensedStates = data.licensedStates.map((state) =>
      state.state_id === state_id
        ? {
            ...state,
            license_type_id: value,
            // license_type: isOtherLicenseType,
          }
        : state
    );

    setData({ ...data, licensedStates: updatedLicensedStates });
  };

  const handleLicenseFileUpload = async (event, state_id) => {
    // console.log("data state id", state_id); // This should now reflect the correct state_id

    const file = event.target.files[0];

    if (file.size > 5e6) {
      enqueueSnackbar("File Size Exceeds.", { variant: "warning" });
      return;
    }

    // Set loading state for the specific state_id
    setLicenseFileLoading((prev) => ({ ...prev, [state_id]: true }));
    // Initialize the progress for this file upload
    setLicenseFilePercent((prev) => ({ ...prev, [state_id]: 0 }));

    if (
      ["image/png", "image/jpg", "image/jpeg", "application/pdf"].includes(
        file?.type
      )
    ) {
      const dirName = moment().format("MMMM_YYYY");
      const storageRef = ref(storage, `${dirName}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          // Update the local progress for the specific state_id
          setLicenseFilePercent((prev) => ({ ...prev, [state_id]: percent }));
        },
        (err) => {
          enqueueSnackbar(err.message, { variant: "error" });
          // Set loading state for the specific state_id to false
          setLicenseFileLoading((prev) => ({ ...prev, [state_id]: false }));
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            enqueueSnackbar("File uploaded Successfully.", {
              variant: "success",
            });

            // Update file name in local state for display purposes
            setLicenseFileNames((prev) => ({
              ...prev,
              [state_id]: file.name, // Store the file name for this state_id
            }));

            // Update the licensedStates with only the URL of the uploaded document
            const updatedLicensedStates = data.licensedStates.map((state) =>
              state.state_id === state_id
                ? {
                    ...state,
                    license: url, // Only store the URL in the data state
                  }
                : state
            );

            setData({ ...data, licensedStates: updatedLicensedStates });
            // Set loading state for the specific state_id to false
            setLicenseFileLoading((prev) => ({ ...prev, [state_id]: false }));
          });
        }
      );
    } else {
      enqueueSnackbar("Incorrect file format.", { variant: "error" });
      // Set loading state for the specific state_id to false
      setLicenseFileLoading((prev) => ({ ...prev, [state_id]: false }));
    }
  };

  const handlelicense_typeChange = (value, state_id) => {
    const updatedLicensedStates = data.licensedStates.map((state) =>
      state.state_id === state_id ? { ...state, license_type: value } : state
    );
    setData({ ...data, licensedStates: updatedLicensedStates });
  };

  const handleLicenseFieldChange = (state_id, fieldName, value) => {
    // Update the specific field for a specific state
    const updatedStates = data.licensedStates.map((state) =>
      state.state_id === state_id ? { ...state, [fieldName]: value } : state
    );
    setData({ ...data, licensedStates: updatedStates });
  };

  const handleLicenseNumberChange = (state_id, value) => {
    // Update the specific state's license_number field
    const updatedStates = data.licensedStates.map((state) =>
      state.state_id === state_id ? { ...state, license_number: value } : state
    );
    // Update the data with the modified licensedStates
    setData({ ...data, licensedStates: updatedStates });
  };

  const handleRemoveDocument = (state_id) => {
    // Update the licensedStates to remove the document for the specified state_id
    const updatedLicensedStates = data.licensedStates.map((state) =>
      state.state_id === state_id
        ? { ...state, license: null } // Set the licenseDocDetail to null
        : state
    );

    // Update the state with the new licensedStates
    setData({ ...data, licensedStates: updatedLicensedStates });

    // Optionally, show a notification
    enqueueSnackbar("Document removed successfully.", { variant: "success" });
  };

  const removeCVFile = () => {
    setCvDocDetail(null);
    enqueueSnackbar("File Removed Successfully", { variant: "success" });
  };

  const removeBoardCertificate = () => {
    setBoardCertificateDocDetail(null);
    enqueueSnackbar("File Removed Successfully", { variant: "success" });
  };

  const removeAdditionalBoardCertificate = () => {
    setAdditionalCertificateDetail(null)
    enqueueSnackbar("File Removed Successfully", { variant: "success" });
  };

  return (
    <div className="min-h-screen">
      <div
        className="d-flex align-items-center gap-3 py-3 rounded-top px-3"
        style={{ background: "#DCD0EB" }}
      >
        <img src="/assets/img/work_black_24dp 2.png" alt="" />
        <p
          className="font-inter font-w500 text-black"
          style={{ color: "#21231E", fontSize: "24px" }}
        >
          Professional Details
        </p>
      </div>
      <div>
        {/* Register as field */}
        <Row className="mx-3 my-2">
          <TextField
            required
            id="outlined-select-currency"
            select
            label="What would you like to register as?"
            className="my-2"
            value={data?.register_as_id}
            onChange={(e) => onRegisterAsChange(e.target.value)}
          >
            {registerAsList?.map((item, index) => (
              <MenuItem key={index} value={item?.id}>
                {item?.name}
              </MenuItem>
            ))}
          </TextField>
        </Row>

        {/* Language & Years of Experience */}
        <div className="d-flex w-[90%] gap-3 mx-3 my-2">
          {data?.Register_fields?.language_ids && (
            <Stack spacing={3} className="w-100">
              <Autocomplete
                multiple
                required
                limitTags={2}
                id="checkboxes-tags-demo"
                options={languagesList}
                value={data?.languages || []}
                onChange={(event, newValue) => {
                  setData({ ...data, languages: newValue });
                }}
                disableCloseOnSelect
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option?.name}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      required
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option?.name}
                  </li>
                )}
                renderTags={() => null}
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      label="Languages*"
                      placeholder="Languages"
                    />
                    <div className="mt-2">
                      {data?.languages?.map((language, index) => (
                        <Chip
                          key={index}
                          label={language?.name}
                          onDelete={() => {
                            setData({
                              ...data,
                              languages: data?.languages.filter(
                                (lang) => lang !== language
                              ),
                            });
                          }}
                          style={{ marginRight: 4, marginBottom: 4 }}
                        />
                      ))}
                    </div>
                  </>
                )}
              />
            </Stack>
          )}
          {data?.Register_fields?.experience && (
            <TextField
              select
              required
              value={data?.experience}
              id="outlined-select-experience"
              label="Years of Experience"
              className="w-100"
              onChange={(e) => setData({ ...data, experience: e.target.value })}
            >
              {yearsOfExp?.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </TextField>
          )}
        </div>
      </div>

      {/* Schooling/training documents */}
      <div className="mx-3 my-3">
        {data?.Register_fields?.documents && (
          <p
            className="font-inter font-w600"
            style={{ color: "#21231E", fontSize: "14px" }}
          >
            Please upload any relevant documents of relevant schooling,
            training, and/or training certifications (Optional)
          </p>
        )}
        <Row className="">
          {data?.Register_fields?.documents && (
            <Form.Group as={Col} className="position-relative mb-3 mt-2 col-4">
              {schoolOrTrainingDocumentLoading ? (
                <div className="file-upload">
                  <div className="upload-icon h-100 col-2">
                    <UploadFileRoundedIcon />
                  </div>
                  <div className="h-100 d-flex align-items-center justify-content-around w-100 ps-3">
                    <CircularProgress sx={{ color: "#191c1e" }} />
                  </div>
                </div>
              ) : trainingCertificateDocDetail ? (
                <div className="file-upload">
                  <div className="upload-icon h-100 col-2">
                    <UploadFileRoundedIcon />
                  </div>
                  <div className="h-100 d-flex align-items-center justify-content-around w-100 ps-3">
                    <p
                      className="font-inter"
                      style={{
                        color: "#6A6C6A",
                        fontSize: 14,
                      }}
                    >
                      {trainingCertificateDocDetail.name &&
                      trainingCertificateDocDetail.name.length > 20
                        ? trainingCertificateDocDetail.name.substring(0, 20) +
                          "..."
                        : trainingCertificateDocDetail.name}
                    </p>
                    <CloseIcon
                      onClick={() => {
                        setTrainingCertificateDocDetail(null);
                        enqueueSnackbar("Document removed successfully", {
                          variant: "success",
                        });
                      }}
                    />
                  </div>
                </div>
              ) : (
                <>
                  <Form.Control
                    multiple
                    size="md"
                    accept="image/*,.pdf"
                    type="file"
                    required
                    name="file"
                    placeholder="Upload Relevant Document"
                    onChange={handleFileUpload}
                    className="file-input d-none"
                    ref={trainingCertificate}
                  />
                  <div
                    className="file-upload"
                    onClick={() => trainingCertificate.current.click()}
                  >
                    <div className="upload-icon h-100 col-2">
                      <UploadFileRoundedIcon />
                    </div>
                    <div className="h-100 d-flex align-items-center w-100 ps-3">
                      <p
                        className="font-inter"
                        style={{
                          color: "#6A6C6A",
                          fontSize: 14,
                        }}
                      >
                        Upload Relevant Document
                      </p>
                    </div>
                  </div>
                </>
              )}

              <Form.Label
                className="font-inter font-w500"
                style={{ color: "#6A6C6A", fontSize: "14px" }}
              >
                Only .jpg, .jpeg, .png, .pdf allowed up to 5mb
              </Form.Label>
            </Form.Group>
          )}
        </Row>

        <Row>
          {data?.Register_fields?.areas_of_interest && (
            <Stack spacing={3} className="col-6">
              <Autocomplete
                multiple
                required
                id="checkboxes-tags-demo"
                options={area_interestList}
                value={data?.areaOfInterest ?? []}
                disableCloseOnSelect
                getOptionLabel={(option) => option?.name}
                onChange={onChangeAutoAreaOfInterest}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option?.name}
                  </li>
                )}
                renderTags={() => null}
                renderInput={(params) => (
                  <>
                    <TextField
                      required
                      {...params}
                      label="Specialization"
                      placeholder="Select your specialization"
                    />
                    {/* Render selected options under the TextField */}
                    <div className="mt-2">
                      {data?.areaOfInterest?.map((option, index) => (
                        <Chip
                          key={index}
                          label={option?.name}
                          onDelete={() =>
                            setData({
                              ...data,
                              areaOfInterest: data?.areaOfInterest?.filter(
                                (selectedOption) => selectedOption !== option
                              ),
                            })
                          }
                          style={{ marginRight: 4, marginBottom: 4 }}
                        />
                      ))}
                    </div>
                  </>
                )}
              />
            </Stack>
          )}
        </Row>
      </div>

      {/* School and Year of Completion */}
      <div className="d-flex w-[90%] gap-3 mx-3 my-2">
        {data?.Register_fields?.school && (
          <TextField
            inputProps={{ minLength: 10, maxLength: 100 }}
            required
            id="outlined-select-currency"
            label="School"
            className="w-100"
            value={data?.school}
            onChange={(e) => setData({ ...data, school: e.target.value })}
          />
        )}
        {data?.Register_fields?.graduation_year && (
          <TextField
            select
            required
            label="Year of completion"
            className="w-100"
            onChange={handleYearChange}
            helperText="Graduation/Doctorate"
            variant="outlined"
            value={data?.graduation_year}
          >
            {years?.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </TextField>
        )}
      </div>

      {/* CV/Resume & NPI Number*/}
      <div className="d-flex w-[90%] align-items-center justify-content-center gap-3 mx-3 my-2">
        {data?.Register_fields?.npi_number && (
          <TextField
            required
            inputProps={{ minLength: 10, maxLength: 10, mask: "xxx-xx-xxxx" }}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            id="outlined"
            label="Provide your NPI number"
            className="w-100 mb-4"
            value={data?.NpiNumber}
            onChange={(e) => setData({ ...data, NpiNumber: e.target.value })}
          />
        )}

        {data?.Register_fields?.resume && (
          <div className="w-100 d-flex flex-column justify-content-evenly">
            <p
              className="font-inter font-w600 mb-2"
              style={{ color: "#21231E", fontSize: "14px" }}
            >
              Please upload your CV/Resume* <br />
            </p>

            <div className="d-flex gap-2 my-2 align-items-center">
              <Form.Group as={Col} className="position-relative mb-3 w-100">
                {cVFileLoading ? (
                  <div className="file-upload">
                    <div className="upload-icon h-100 col-2">
                      <UploadFileRoundedIcon />
                    </div>
                    <div className="h-100 d-flex align-items-center justify-content-around w-100 ps-3">
                      <CircularProgress sx={{ color: "#191c1e" }} />
                    </div>
                  </div>
                ) : cvDocDetail ? (
                  <div className="file-upload">
                    <div className="upload-icon h-100 col-2">
                      <UploadFileRoundedIcon />
                    </div>
                    <div className="h-100 d-flex align-items-center justify-content-between w-100 ps-3">
                      <p
                        className="font-inter"
                        style={{
                          color: "#6A6C6A",
                          fontSize: 14,
                        }}
                      >
                        {cvDocDetail?.name && cvDocDetail?.name.length > 20
                          ? cvDocDetail.name.substring(0, 20) + "..."
                          : cvDocDetail.name}
                      </p>
                      <CloseIcon onClick={removeCVFile} />
                    </div>
                  </div>
                ) : (
                  <>
                    <Form.Control
                      multiple
                      type="file"
                      accept=".pdf"
                      required
                      name="file"
                      placeholder="CV/Resume"
                      onChange={handleCVFileUpload}
                      className="d-none"
                      ref={cvRef}
                    />
                    <div
                      className="file-upload"
                      onClick={() => cvRef.current.click()}
                    >
                      <div className="upload-icon h-100 col-2">
                        <UploadFileRoundedIcon />
                      </div>
                      <div className="h-100 d-flex align-items-center w-100 ps-3">
                        <p
                          className="font-inter"
                          style={{
                            color: "#6A6C6A",
                            fontSize: 14,
                          }}
                        >
                          Upload CV
                        </p>
                      </div>
                    </div>
                  </>
                )}
                <Form.Label
                  className="font-inter font-w500 mt-2"
                  style={{
                    color: "#6A6C6A",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Only .pdf allowed up to 5MB
                </Form.Label>
              </Form.Group>
            </div>
          </div>
        )}
      </div>

      {/* Qualification & Upload Board certificate & Upload Additional Certificate*/}
      <div className="d-flex w-[90%] align-items-center justify-content-between gap-3 mx-3 my-2">
        {data?.Register_fields?.qualification && (
          <TextField
            required
            id="outlined"
            label="Qualification"
            className="mt-2 w-75 "
            value={data?.qualification}
            onChange={(e) =>
              setData({ ...data, qualification: e.target.value })
            }
          />
        )}
        {data?.Register_fields?.board_certification && (
          <div className="w-100 d-flex flex-column justify-content-evenly">
            <p
              className="font-inter font-w600 px-3"
              style={{ color: "#21231E", fontSize: "14px" }}
            >
              Please upload the transcript of your highest qualification*
            </p>
            <div className="d-flex gap-2 mx-2 my-2">
              <Form.Group as={Col} className="position-relative">
                {(boardCertificationLoading && (
                  <>
                    <div className="file-upload">
                      <div className="upload-icon h-100 col-2">
                        <UploadFileRoundedIcon />
                      </div>
                      <div className="h-100 d-flex align-items-center justify-content-around w-100 ps-3">
                        <CircularProgress sx={{ color: "#191c1e" }} />
                      </div>
                    </div>
                  </>
                )) ||
                  (boardCertificateDocDetail ? (
                    <>
                      <div className="file-upload">
                        <div className="upload-icon h-100 col-2">
                          <UploadFileRoundedIcon />
                        </div>
                        <div className="h-100 d-flex align-items-center justify-content-around w-100 ps-3">
                          <p
                            className="font-inter"
                            style={{
                              color: "#6A6C6A",
                              fontSize: 14,
                            }}
                          >
                            {boardCertificateDocDetail?.name &&
                            boardCertificateDocDetail?.name?.length > 40
                              ? boardCertificateDocDetail?.name?.substring(
                                  0,
                                  40
                                ) + "..."
                              : boardCertificateDocDetail?.name}
                          </p>
                          <CloseIcon onClick={removeBoardCertificate} />
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <Form.Control
                        multiple
                        type="file"
                        required
                        accept="image/*,.pdf"
                        name="file"
                        placeholder="Upload Transcript"
                        onChange={handleBoardCertificationFileUpload}
                        ref={boardCertificateRef}
                        className="d-none"
                      />
                      <div
                        className="file-upload"
                        onClick={() => boardCertificateRef.current.click()}
                      >
                        <div className="upload-icon h-100 col-2">
                          <UploadFileRoundedIcon />
                        </div>
                        <div className="h-100 d-flex align-items-center w-100">
                          <p
                            className="font-inter"
                            style={{
                              color: "#6A6C6A",
                              fontSize: 14,
                              padding: "0 10px",
                            }}
                          >
                            Upload Transcript
                          </p>
                        </div>
                      </div>
                    </>
                  ))}
                <Form.Label
                  className="font-inter font-w500"
                  style={{
                    color: "#6A6C6A",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Only .jpg, .jpeg, .png, .pdf allowed up to 5mb
                </Form.Label>
              </Form.Group>
            </div>
          </div>
        )}
        <div className="w-100 d-flex flex-column justify-content-evenly">
          {data?.Register_fields?.additional_certificates && (
            <p
              className="font-inter font-w600 mx-3"
              style={{ color: "#21231E", fontSize: "14px" }}
            >
              Upload any additional certifications (Optional)
            </p>
          )}
          <div className="d-flex w-[90%] gap-3 mx-3 mt-4">
            {data?.Register_fields?.additional_certificates && (
              <Form.Group
                as={Col}
                className="position-relative w-100 col-12 mb-3 mt-2"
              >
                {additionalCertificateLoading ? (
                  <div className="file-upload">
                    <div className="upload-icon h-100 col-2">
                      <UploadFileRoundedIcon />
                    </div>
                    <div className="h-100 d-flex align-items-center justify-content-around w-100 ps-3">
                      <CircularProgress sx={{ color: "#191c1e" }} />
                    </div>
                  </div>
                ) : additionalCertificatesDetail?.length > 0 ? (
                  <div className="file-upload">
                    <div className="upload-icon h-100 col-2">
                      <UploadFileRoundedIcon />
                    </div>
                    <div className="h-100 d-flex align-items-center justify-content-around w-100 ps-3">
                      {additionalCertificatesDetail?.map((item) => (
                        <div
                          className="d-flex align-items-center justify-content-between w-100 my-2"
                          key={item.fileName}
                        >
                          <p
                            className="font-inter"
                            style={{
                              color: "#6A6C6A",
                              fontSize: 14,
                              padding: "0 10px",
                            }}
                          >
                            {item?.fileName?.length > 20
                              ? item?.fileName.substring(0, 20) + "..."
                              : item?.fileName}
                          </p>
                          <CloseIcon
                            onClick={removeAdditionalBoardCertificate}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <>
                    <Form.Control
                      multiple
                      type="file"
                      required
                      name="file"
                      accept="image/*,.pdf"
                      placeholder="Upload Additional Certifications"
                      className="my-2 d-none"
                      onChange={handleAdditionalCertificateFileUpload}
                      ref={additionalCertificatesRef}
                    />
                    <div
                      className="file-upload"
                      onClick={() => additionalCertificatesRef.current.click()}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="upload-icon h-100 col-2">
                        <UploadFileRoundedIcon />
                      </div>
                      <div className="h-100 d-flex align-items-center w-100 ps-3">
                        <p
                          className="font-inter"
                          style={{
                            color: "#6A6C6A",
                            fontSize: 14,
                          }}
                        >
                          Upload Additional Certificate
                        </p>
                      </div>
                    </div>
                  </>
                )}

                <Form.Label
                  className="font-inter font-w500"
                  style={{
                    color: "#6A6C6A",
                    fontSize: "14px",
                    whiteSpace: "nowrap",
                  }}
                >
                  Only .jpg, .jpeg, .png, .pdf allowed up to 5MB
                </Form.Label>
              </Form.Group>
            )}
          </div>
        </div>
      </div>

      {/* License States */}
      {data?.Register_fields?.licensed_state_ids && (
        <div className="px-2 my-2">
          <div className="d-flex w-100 gap-3 mx-2 my-2">
            {/* Dropdown to choose multiple states */}
            <Autocomplete
              multiple
              id="licensed-states"
              options={licenseStateList}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              onChange={handleStateChange}
              className="col-6"
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox checked={selected} style={{ marginRight: 8 }} />
                  {option.name}
                </li>
              )}
              renderTags={() => null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="In which state are you licensed to practice?*"
                  placeholder="Select states"
                />
              )}
            />
          </div>

          {/* Mapping over licensedStates */}
          {data?.licensedStates?.map((state, index) => (
            <div className="d-flex flex-column gap-3 mx-2 my-4" key={index}>
              {/* Show the selected state */}
              <div className="d-flex gap-3">
                {" "}
                <TextField
                  label="Licensed State"
                  value={
                    licenseStateList.find((item) => item.id === state.state_id)
                      ?.name || ""
                  }
                  disabled
                  className="w-100"
                />
                {/* Date of expiration */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Date of expiration *"
                    value={
                      state.license_expiry_date
                        ? dayjs(state.license_expiry_date)
                        : null
                    }
                    minDate={dayjs()}
                    onChange={(newValue) =>
                      handleLicenseFieldChange(
                        state.state_id,
                        "license_expiry_date",
                        moment(newValue.toDate()).format("YYYY-MM-DD")
                      )
                    }
                    className="w-100"
                  />
                </LocalizationProvider>{" "}
                <TextField
                  required
                  label="License Number"
                  className="w-100"
                  value={state.license_number} // Bind the value to the state's license_type
                  onChange={(e) => {
                    const { value } = e.target;
                    if (/^\d*$/.test(value)) {
                      // Allows empty input or numeric values only
                      handleLicenseNumberChange(state.state_id, value);
                    }
                  }} // Handle change for the correct state
                />
              </div>

              <div className="d-flex gap-3 ">
                <TextField
                  select
                  label="Choose License Type"
                  className="col-4"
                  value={state.license_type_id}
                  onChange={(e) =>
                    onLicenseTypeChange(e.target.value, state.state_id)
                  }
                >
                  {licenseTypeList.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>

                {/* Specify Other License Type */}
                {state?.license_type_id === 7 && (
                  <TextField
                    required
                    label="Specify other license type"
                    className="w-75"
                    value={state.license_type}
                    onChange={(e) =>
                      handlelicense_typeChange(e.target.value, state.state_id)
                    }
                  />
                )}

                <Form.Group
                  as={Col}
                  className="position-relative mb-3"
                  key={state.state_id}
                >
                  {(licenseFileLoading[state.state_id] && (
                    <div className="file-upload">
                      <div className="upload-icon h-100 col-2">
                        <UploadFileRoundedIcon />
                      </div>
                      <div className="h-100 d-flex align-items-center justify-content-around w-100 ps-3">
                        <CircularProgress
                          sx={{ fontSize: "10px", color: "#6A6C6A" }}
                          // value={licenseFilePercent[state.state_id] || 0}
                        />
                      </div>
                    </div>
                  )) ||
                    (state.license ? (
                      <div className="file-upload">
                        <div className="upload-icon h-100 col-2">
                          <UploadFileRoundedIcon />
                        </div>
                        <div className="h-100 d-flex align-items-center justify-content-around w-100 ps-3">
                          <p
                            className="font-inter"
                            style={{
                              color: "#6A6C6A",
                              fontSize: 14,
                            }}
                          >
                            {licenseFileNames[state.state_id] &&
                            licenseFileNames[state.state_id].length > 40
                              ? `${licenseFileNames[state.state_id].substring(
                                  0,
                                  40
                                )}...`
                              : licenseFileNames[state.state_id]}
                          </p>
                          <CloseIcon
                            onClick={() => handleRemoveDocument(state.state_id)}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        <Form.Control
                          type="file"
                          accept="image/*,.pdf"
                          required
                          name="file"
                          placeholder="Upload License"
                          onChange={(event) =>
                            handleLicenseFileUpload(event, state.state_id)
                          }
                          ref={(el) =>
                            (licenseDocRefs.current[state.state_id] = el)
                          } // Attach ref for each state
                          className="d-none"
                        />
                        <div
                          className="file-upload"
                          onClick={() => {
                            // Trigger the file input click
                            const inputRef =
                              licenseDocRefs.current[state.state_id];
                            if (inputRef) {
                              inputRef.click();
                            } else {
                              console.error("File input reference not found");
                            }
                          }}
                        >
                          <div className="upload-icon h-100 col-2">
                            <UploadFileRoundedIcon />
                          </div>
                          <div className="h-100 d-flex align-items-center w-100 ps-3">
                            <p
                              className="font-inter"
                              style={{
                                color: "#6A6C6A",
                                fontSize: 14,
                              }}
                            >
                              Upload License Document
                            </p>
                          </div>
                        </div>
                      </>
                    ))}
                  <Form.Label
                    className="font-inter font-w500"
                    style={{
                      color: "#6A6C6A",
                      fontSize: "14px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Only .jpg, .jpeg, .png, .pdf allowed up to 5mb
                  </Form.Label>
                </Form.Group>
              </div>
            </div>
          ))}
        </div>
      )}

      {/* What do you treat */}
      <div className="d-flex w-[90%] gap-3 mx-3 my-2">
        {data?.Register_fields?.treatment_ids && (
          <Stack spacing={3} className="my-2 col-4">
            <Autocomplete
              multiple
              required
              id="checkboxes-tags-demo"
              options={treatmentList}
              disableCloseOnSelect
              getOptionLabel={(option) => option?.name}
              value={
                treatmentList.filter((treatment) =>
                  data?.treatment_ids?.includes(treatment.id)
                ) || []
              }
              onChange={(event, newValue) => {
                setData({
                  ...data,
                  treatment_ids: newValue.map((option) => option.id),
                });
              }}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    required
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option?.name}
                </li>
              )}
              renderTags={() => null}
              renderInput={(params) => (
                <>
                  <TextField
                    {...params}
                    label="What do you treat?*"
                    placeholder="What do you treat?"
                  />
                  <div className="mt-2">
                    {data?.treatment_ids?.map((id) => {
                      const option = treatmentList.find(
                        (treatment) => treatment.id === id
                      );
                      return option ? (
                        <Chip
                          key={id}
                          label={option.name}
                          onDelete={() =>
                            setData({
                              ...data,
                              treatment_ids: data?.treatment_ids.filter(
                                (selectedId) => selectedId !== id
                              ),
                            })
                          }
                          style={{ marginRight: 4, marginBottom: 4 }}
                        />
                      ) : null;
                    })}
                  </div>
                </>
              )}
            />
          </Stack>
        )}
      </div>

      {/* Write your experience */}
      {data?.Register_fields?.about_experience && (
        <Row className="mx-2 my-2">
          <p
            className="font-inter font-w600"
            style={{ color: "#21231E", fontSize: "14px" }}
          >
            Write about your professional experience and specialization *
          </p>
          <Form.Group
            className="mb-3 col-12"
            controlId="exampleForm.ControlTextarea1"
          >
            <TextField
              maxRows={4}
              onKeyDown={(event) => {
                if (!/[0-9a-zA-Z. -&%+/,'"]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              id="outlined-multiline-static"
              label="This text will appear on the client's screen"
              multiline
              value={data?.about_experience}
              rows={4}
              onChange={(e) =>
                setData({ ...data, about_experience: e.target.value })
              }
              className="w-100 mt-3"
            />
          </Form.Group>
        </Row>
      )}

      {/* Insurance and Taxonomy fields */}
      {data?.RegisterAs === "Therapist" && (
        <>
          <div>
            <div className="mx-3 my-2 d-flex gap-2 w-90">
              <TextField
                required
                id="outlined-select-currency"
                select
                label="Are you part of Insurance Network?"
                className="my-2 flex-1 w-100"
                value={data?.part_of_insurance_network || ""}
                onChange={(e) =>
                  setData({
                    ...data,
                    part_of_insurance_network: e.target.value,
                  })
                }
              >
                {booleanOptions.map((item, index) => (
                  <MenuItem key={index} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            {data?.part_of_insurance_network === "YES" && (
              <div className="d-flex gap-2 m-3">
                <Stack spacing={3} className="col-6">
                  <Autocomplete
                    multiple
                    required
                    id="insurance-network-dropdown"
                    options={networkList}
                    getOptionLabel={(option) => option?.name}
                    value={
                      networkList.filter((network) =>
                        data?.insurance_network_ids?.includes(network.id)
                      ) || []
                    }
                    onChange={(event, newValue) => {
                      setData({
                        ...data,
                        insurance_network_ids: newValue.map(
                          (option) => option.id
                        ),
                      });
                    }}
                    disableCloseOnSelect
                    renderTags={() => null}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option?.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Insurance Network*"
                        placeholder="Select Payors"
                      />
                    )}
                  />
                  {/* Display selected options under the input */}
                  <div className="mt-2">
                    {data?.insurance_network_ids?.map((id) => {
                      const option = networkList.find(
                        (network) => network.id === id
                      );
                      return option ? (
                        <Chip
                          key={id}
                          label={option.name}
                          onDelete={() =>
                            setData({
                              ...data,
                              insurance_network_ids:
                                data?.insurance_network_ids.filter(
                                  (selectedId) => selectedId !== id
                                ),
                            })
                          }
                          style={{ marginRight: 4, marginBottom: 4 }}
                        />
                      ) : null;
                    })}
                  </div>
                </Stack>

                <Stack spacing={3} className="col-6">
                  <Autocomplete
                    multiple
                    required
                    id="taxonomy-code-dropdown"
                    options={taxonomyCodes}
                    getOptionLabel={(option) => option?.name}
                    value={
                      taxonomyCodes.filter((code) =>
                        data?.taxonomy_code_ids?.includes(code.id)
                      ) || []
                    }
                    onChange={(event, newValue) => {
                      setData({
                        ...data,
                        taxonomy_code_ids: newValue.map((option) => option.id),
                      });
                    }}
                    disableCloseOnSelect
                    renderTags={() => null}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option?.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Taxonomy*"
                        placeholder="Add Taxonomy"
                      />
                    )}
                  />
                  {/* Display selected options under the input */}
                  <div className="mt-2">
                    {data?.taxonomy_code_ids?.map((id) => {
                      const option = taxonomyCodes.find(
                        (code) => code?.id === id
                      );
                      return option ? (
                        <Chip
                          key={id}
                          label={option.name}
                          onDelete={() =>
                            setData({
                              ...data,
                              taxonomy_code_ids: data?.taxonomy_code_ids.filter(
                                (selectedId) => selectedId !== id
                              ),
                            })
                          }
                          style={{ marginRight: 4, marginBottom: 4 }}
                        />
                      ) : null;
                    })}
                  </div>
                </Stack>
              </div>
            )}
          </div>
        </>
      )}

      {data?.Register_fields && (
        <div className="d-flex align-items-center justify-content-end my-5">
          <ButtonGroup className="d-flex flex-row align-items-center justify-content-end gap-4 me-3 my-3">
            <Button
              className="rounded-3 border-0 text-white"
              style={{
                backgroundColor: "#73589B",
                whiteSpace: "nowrap",
                textTransform: "none",
                minWidth: "120px",
                height: "38px",
              }}
              onClick={onClickProfileData}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <div className="d-flex align-items-center gap-2">
                  <CircularProgress size={20} sx={{ color: "white" }} />
                  <span>Saving...</span>
                </div>
              ) : (
                "Save & Next"
              )}
            </Button>
          </ButtonGroup>
        </div>
      )}

      <ToastContainer />
    </div>
  );
};

export default RegisterAsProfessionalProfessionalDetailTab;
