import React, { useState, useEffect } from "react";
import AppointmentCard from "../cards/AppointmentCard";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import axios from "axios";

//Dialog
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

//Date Picker
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

//Text Field
import TextField from "@mui/material/TextField";

import moment from "moment";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";

//Switch
import Switch from "@mui/material/Switch";

//Snackbar
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useSnackbar } from "notistack";

//Icons
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";

import isUserLoggedIn from "../helpers/redirect";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../auth";

//JWT
import jwtDecode from "jwt-decode";

//Firebase
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../../firebaseConfig";

//Loader spinner
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";

import { getToken } from "../APIS/clientAPIS";
import { Pagination } from "@mui/material";
import { CalenderSvg } from "../helpers/Svg";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

dayjs.extend(localizedFormat);

const AppointmentDefaultView = ({ selectedTab, loading, setLoading }) => {
  const [appointmentList, setAppointmentList] = useState([]);
  const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
  const [slotsList, setSlotsList] = useState([]);
  const [checked, setChecked] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [clientData, setClientData] = useState({
    slotID: 0,
    clientID: 0,
    clientName: "",
    clientImage: "",
    clientType: "",
    appointmentID: 0,
    appointmentDate: "",
    appointmentTime: "",
    clientStatus: "",
    clientCallingMode: "",
    callDuration: "",
  });

  const [openNotesModal, setOpenNotesModal] = useState(false);
  const [note, setNote] = useState("");
  const [openPrescriptionModal, setOpenPrescriptionModal] = useState(false);
  const [prescriptionName, setPrescriptionName] = useState("");
  const [prescriptionNote, setPrescriptionNote] = useState("");
  const [selectedFiles, setSelectedFiles] = useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [openCancelConfirmationModal, setOpenCancelConfirmationModal] =
    useState(false);

  const [bearerToken, setBearerToken] = useState(
    sessionStorage.getItem("token")
  );

  //Loaders States
  const [isFileLoading, setIsFileLoading] = useState(null);
  const [isCancelled, setIsCancelled] = useState(null);
  const [isPrescriptionAdded, setIsPrescriptionAdded] = useState(null);
  const [isRescheduled, setIsRescheduled] = useState(null);
  const [isNoteAdded, setIsNoteAdded] = useState(null);
  const [arrayLength, setArrayLength] = useState(16);
  const { currentUser, userType, logout } = useAuth();
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const headers = {
    "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    "Content-Type": "application/json",
  };

  const postSlotsData = async () => {
    try {
      const formattedDate = moment(
        clientData?.appointmentDate
          ? clientData?.appointmentDate
          : selectedDate || dayjs().format("DD/MM/YYYY"),
        "DD/MM/YYYY"
      ).format("YYYY-MM-DD");

      const url = `https://us.uwc.world/new-uwc/uwc_expert/appointment/slots?expert_id=${currentUser?.UserId}&date=${formattedDate}&duration=50&timezone=US/Pacific`;

      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });

      const data = await response.json();

      if (data && data.code === 200 && data.data?.slots) {
        // Get the date key from the response (e.g. "2025-03-31")
        const dateKey = Object.keys(data.data?.slots);

        // Transform the slots data into the required format
        const transformedSlots = data.data?.slots[dateKey].map(
          (slot, index) => {
            const slotTime = dayjs(slot.time);
            const endTime = slotTime.add(50, "minute");

            return {
              id: index + 1, // Generate unique ID for each slot
              slot: `${slotTime.format("hh:mm A")} - ${endTime.format(
                "hh:mm A"
              )}`, // Format: "09:00 AM - 09:50 AM"
            };
          }
        );
        setSlotsList(transformedSlots);
      } else {
        console.error("Unexpected response structure:", data);
        setSlotsList([]);
      }
    } catch (error) {
      console.error("Error fetching slots:", error);
      setSlotsList([]);
    }
  };

  //Slots List (Reschedule Dialog)
  useEffect(() => {
    if (clientData?.appointmentDate !== "") {
      postSlotsData();
    }
  }, [clientData]);

  const appointmentPayload = {
    expert_id: currentUser?.UserId,
    type: selectedTab.toLowerCase(),
    datetime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    page: page,
    limit: 9,
  };
  const postAppointmentsData = async () => {
    try {
      const response = await fetch(
        `https://odoo.unitedwecare.ca/new-uwc/expert/dashboard/appointments`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(appointmentPayload),
        }
      );
      const postResponse = await response.json();
      const { appointments, total_pages } = postResponse?.data;

      setAppointmentList(appointments);
      setTotalPage(total_pages);

      setLoading(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //Appointments List
  useEffect(() => {
    postAppointmentsData();
    setPage(1);
  }, [selectedTab]);

  useEffect(() => {
    postAppointmentsData();
  }, [page]);

  //console.log("APP", appointmentList)
  //Snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  ///Reschedule Region

  //Reschedule
  const handleReschedule = (
    clientName,
    clientImage,
    clientType,
    appointmentID,
    appointmentDate,
    appointmentTime,
    clientCallingMode,
    clientStatus,
    callDuration
  ) => {
    // Perform actions to open the reschedule dialog
    // You can access the appointmentID, appointmentDate, and slotID here

    setClientData((prevClientData) => ({
      ...prevClientData,
      clientImage,
      clientType,
      appointmentID,
      appointmentDate,
      appointmentTime,
      clientCallingMode,
      clientStatus,
      callDuration,
    }));

    handleRescheduleOpen();
    //handleRescheduleOpen();
  };

  const handleRescheduleOpen = () => {
    setOpenRescheduleModal(true);
  };

  const handleRescheduleClose = () => {
    setOpenRescheduleModal(false);
  };

  const [selectedDate, setSelectedDate] = useState(null);

  const onToDateChange = (date) => {
    const formattedDate = dayjs(date).format("DD/MM/YYYY");
    setSelectedDate(date);
    setClientData((prevClientData) => ({
      ...prevClientData,
      appointmentDate: formattedDate,
    }));
  };

  const handleRescheduleAppointment = async (retryCount = 0) => {
    const MAX_RETRY = 3; // Maximum number of retries
    setIsRescheduled(false);

    let sessionBearerToken = sessionStorage.getItem("token");

    // Format the appointment date and time
    const appointmentDateTime = dayjs(
      `${clientData?.appointmentDate} ${clientData?.appointmentTime}`,
      "DD/MM/YYYY hh:mm A"
    ).format("YYYY-MM-DD HH:mm:ss");

    const payload = {
      uwc_appointmentid: clientData?.appointmentID,
      appointment_time: appointmentDateTime,
      timezone: "Asia/Kolkata",
      token: sessionBearerToken,
      duration: parseInt(clientData?.callDuration.split(" ")[0]),
    };

    const config = {
      headers: {
        // 'accept': 'application/json, text/plain, */*',
        "content-type": "application/json",
        authorization: `Bearer ${sessionBearerToken}`,
        "x-api-key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
      },
    };

    try {
      const response = await axios.post(
        "https://us.uwc.world/new-uwc/uwc_expert/appointment/reschedule",
        payload,
        config
      );

      const postResponse = response.data;

      if (postResponse?.code === 200) {
        setSnackbarMessage(postResponse?.data?.message);
        window.location.reload();
        setOpenSnackbar(true);
        handleRescheduleClose();
        setIsRescheduled(true);
        postAppointmentsData();
      } else if (postResponse?.code === 401 && retryCount < MAX_RETRY) {
        getToken(currentUser?.Emailid).then((res) => {
          setBearerToken(res.accessToken);
          setTimeout(() => handleRescheduleAppointment(retryCount + 1), 0);
        });
      } else {
        setSnackbarMessage(
          postResponse?.message || "Failed to reschedule appointment"
        );
        setOpenSnackbar(true);
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle axios specific errors
      const errorMessage =
        error.response?.data?.message || "An error occurred while rescheduling";
      setSnackbarMessage(errorMessage);
      setOpenSnackbar(true);
    }
  };

  //Slots Section
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleSlotChange = (slot) => {
    //console.log("slot", slot)
    setClientData((prevClientData) => ({
      ...prevClientData,
      slotID: slot.id,
      appointmentTime: slot?.slot.split(" - ")[0],
    }));
  };

  ///Reschedule Region End

  ///Notes Region

  const handleNotes = (
    clientName,
    clientImage,
    clientType,
    appointmentID,
    appointmentDate,
    appointmentTime,
    clientID
  ) => {
    setClientData((prevClientData) => ({
      ...prevClientData,
      clientName,
      clientImage,
      clientType,
      appointmentID,
      appointmentDate,
      appointmentTime,
      clientID,
    }));
    handleNotesOpen();
  };

  const handleNotesOpen = () => {
    setOpenNotesModal(true);
  };

  const handleNotesClose = () => {
    setOpenNotesModal(false);
  };

  const handleNote = (e) => {
    setNote(e.target.value);
  };

  const handlePostNote = async () => {
    setIsNoteAdded(false);
    const payload = {
      jsonrpc: "2.0",
      params: {
        name: note,
        content: note,
        creator_id: currentUser?.UserId,
        assign_id: clientData?.clientID,
        is_private: "True",
      },
    };

    try {
      const response = await fetch(
        `https://odoo.unitedwecare.ca/uwc_notes/create`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      const message = await postResponse.ResponseCode;

      if (message == 200) {
        setNote("");
        setOpenNotesModal(false);
        setSnackbarMessage("Note sent successfully!");
        setOpenSnackbar(true);
        setIsNoteAdded(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  ///Notes Region End

  ///Prescription Region

  const handlePrescription = (
    clientName,
    clientImage,
    clientType,
    appointmentID,
    appointmentDate,
    appointmentTime
  ) => {
    setClientData((prevClientData) => ({
      ...prevClientData,
      clientName,
      clientImage,
      clientType,
      appointmentID,
      appointmentDate,
      appointmentTime,
    }));

    handlePrescriptionOpen();
  };

  const handlePrescriptionOpen = () => {
    setOpenPrescriptionModal(true);
  };

  const handlePrescriptionClose = () => {
    setOpenPrescriptionModal(false);
  };

  const handlePrescriptionName = (e) => {
    setPrescriptionName(e.target.value);
  };

  const handlePrescriptionNote = (e) => {
    setPrescriptionNote(e.target.value);
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const handlePostPrescription = async (retryCount = 0) => {
    const MAX_RETRY = 3; // Maximum number of retries
    setIsPrescriptionAdded(false);

    const payload = {
      uploaded_by: "expert",
      creator_id: `${currentUser?.UserId}`,
      user_id: `${currentUser?.UserId}`, // You may need to adjust this if the user_id should be different
      clinical_note_id: clientData?.appointmentID?.toString() || "",
      response: "",
      clinical_relevance: true,
      doctor_names: "",
      metadata: {
        image_url: selectedFiles,
        original_file_name: selectedFiles.split("/").pop(), // Extract filename from URL
        new_file_name: `prescription_${dayjs().toISOString()}.${
          selectedFiles.includes("pdf") ? "pdf" : "jpg"
        }`,
        file_type: selectedFiles.split("/")[1] || "jpg",
        file_size: formatFileSize(selectedFiles),
        category_name: "Prescription",
        doctor_name: "",
        text: null,
      },
    };

    const prescriptionHeaders = {
      accept: "application/json",
      token:
        "151669bf09afa74532aa8dba08a677e6d8e6e340c5c6af41c325b033970849ce40373", // You may need to use a dynamic token
      "Content-Type": "application/json",
    };

    try {
      const response = await fetch(
        "https://session-note.uwc.world/clinicalnotes/add-prescription",
        {
          method: "POST",
          headers: prescriptionHeaders,
          body: JSON.stringify(payload),
        }
      );

      const postResponse = await response.json();
      const message = postResponse.ResponseCode || postResponse.code;

      if (message?.status === "success") {
        setPrescriptionName("");
        setPrescriptionNote("");
        setSelectedFiles("");
        handlePrescriptionClose();
        setSnackbarMessage(message?.message);
        setOpenSnackbar(true);
        setIsPrescriptionAdded(true);
      } else if (
        (message == 401 || postResponse?.ResponseCode == 401) &&
        retryCount < MAX_RETRY
      ) {
        getToken(currentUser?.Emailid).then((res) => {
          setBearerToken(res.accessToken);
          setTimeout(() => handlePostPrescription(retryCount + 1), 0);
        });
      } else {
        console.log(postResponse?.ResponseMessage || postResponse?.message);
        setIsPrescriptionAdded(true);
      }
    } catch (error) {
      console.error("Error:", error);
      setSnackbarMessage("Failed to add prescription");
      setOpenSnackbar(true);
      setIsPrescriptionAdded(true);
    }
  };

  //Upload
  const handleFileSelect = async (event) => {
    const file = event.target.files[0];

    setIsFileLoading(true);
    if (
      file?.type === "image/png" ||
      file?.type === "image/jpg" ||
      file?.type === "image/jpeg"
    ) {
      let dirName = moment().format("MMMM_YYYY");
      //console.log("dirName", dirName);
      const storageRef = ref(storage, dirName + "/" + file.name);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          console.log("uploading", percent);
          // update progress
          // setPercent(percent);
        },
        (err) => enqueueSnackbar(err, { variant: "error" }),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            // console.log("file path url ", url);
            //setLoading(false);
            setSelectedFiles(url);
            setIsFileLoading(false);
          });
        }
      );
    } else {
      console.error("Incorrect  file format.");
    }

    //setSelectedFiles(files);
  };
  ///Prescription Region End

  ///Cancel Region

  const handleCancel = async (retryCount = 0) => {
    const MAX_RETRY = 3; // Maximum number of retries
    let sessionBearerToken = sessionStorage.getItem("token");
    setIsCancelled(false);

    const payload = {
      uwc_appointmentid: clientData?.appointmentID,
      token: sessionBearerToken,
    };

    const cancelHeaders = {
      "content-type": "application/json",
      authorization: `Bearer ${sessionBearerToken}`,
      "x-api-key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
    };

    try {
      const response = await fetch(
        `https://us.uwc.world/new-uwc/uwc_expert/appointment/cancel`,
        {
          method: "POST",
          headers: cancelHeaders,
          body: JSON.stringify(payload),
        }
      );
      const postResponse = await response.json();

      if (postResponse?.code === 200) {
        handleCancelConfirmationClose();
        setSnackbarMessage("Cancelled successfully!");
        setOpenSnackbar(true);
        setIsCancelled(true);
        postAppointmentsData();
        window.location.reload();
      } else if (postResponse?.code === 401 && retryCount < MAX_RETRY) {
        getToken(currentUser?.Emailid).then((res) => {
          setBearerToken(res?.accessToken);
          setTimeout(() => handleCancel(retryCount + 1), 0);
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCancelConfirmationOpen = () => {
    setOpenCancelConfirmationModal(true);
  };

  const handleCancelConfirmationClose = () => {
    setOpenCancelConfirmationModal(false);
  };

  const handleCancelAppointment = (
    clientName,
    clientImage,
    clientType,
    appointmentID,
    appointmentDate,
    appointmentTime
  ) => {
    setClientData((prevClientData) => ({
      ...prevClientData,
      clientName,
      clientImage,
      clientType,
      appointmentID,
      appointmentDate,
      appointmentTime,
    }));

    handleCancelConfirmationOpen();
  };

  ///Cancel Region End
  const filteredSlotsData = slotsList?.filter((item) => {
    if (!checked) {
      // Render morning slots (AM)
      return item.slot.split(" - ")[0].includes("AM");
    } else {
      // Render evening slots (PM)
      return item.slot.split(" - ")[0].includes("PM");
    }
  });

  const cancelledAppointments = appointmentList?.filter(
    (item) => item.Status == "Cancelled"
  );
  const openAppointments = appointmentList?.filter(
    (item) => item.Status !== "Cancelled"
  );

  // console.log("cancelledAppointments", cancelledAppointments);
  // console.log("openAppointments", openAppointments)

  return (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          backgroundColor: "#FFF",
          padding: "10px 10px 26px 10px",
          marginTop: "20px",
          marginLeft: 0,
          marginRight: 0,
          borderRadius: "10px",
          width: "100%",
        }}
      >
        {loading ? (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(370px, 1fr))",
              width: "100%",
              gap: "15px",
            }}
          >
            {Array.from(new Array(arrayLength)).map((_, index) => (
              <div
                style={{
                  minWidth: "100%",
                  backgroundColor: "rgb(0,0,0,0.07)",
                  padding: "10px",
                  height: "168px",
                  borderRadius: "10px",
                }}
              >
                <div className="d-flex gap-2 Card--Top my-1 p-1">
                  <Skeleton variant="circular" width={45} height={45} />

                  <div>
                    <Skeleton
                      variant="text"
                      width="8vw"
                      height={20}
                      sx={{ borderRadius: "5px" }}
                    />
                    <p>
                      <Skeleton
                        variant="text"
                        width="5vw"
                        height={20}
                        sx={{ borderRadius: "5px" }}
                      />
                    </p>
                  </div>
                </div>

                <div
                  className="Card--Timings d-flex gap-4 p-2 w-100"
                  style={{ margin: "10px 0" }}
                >
                  <Skeleton
                    variant="rounded"
                    width="45%"
                    height={20}
                    sx={{ borderRadius: "5px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    width="45%"
                    height={20}
                    sx={{ borderRadius: "5px" }}
                  />
                </div>
                <div
                  className="d-flex gap-2 w-100"
                  style={{ margin: "10px 0" }}
                >
                  <Skeleton
                    variant="rounded"
                    width="45%"
                    height={34}
                    sx={{ borderRadius: "20px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    width="45%"
                    height={34}
                    sx={{ borderRadius: "20px" }}
                  />
                  <Skeleton
                    variant="rounded"
                    width="10%"
                    height={40}
                    sx={{ borderRadius: "20px" }}
                  />
                </div>
              </div>
            ))}
          </div>
        ) : appointmentList?.length === 0 ? (
          <div className="NoDataAvailable">
            {CalenderSvg}
            <p>No Appointment data available</p>
          </div>
        ) : (
          <>
            <div
              style={{}}
              className="w-100 row row-cols-1 row-cols-md-2 row-cols-xl-3 "
            >
              {appointmentList?.map((item, index) => (
                <div style={{}} className="p-2 " key={item.id}>
                  <AppointmentCard
                    id={item.ID}
                    clientID={item.UserId}
                    img={item.ProfileImage}
                    name={item.Name}
                    clientType={item.client_type}
                    appointmentID={item.ID}
                    appointmentDate={item.AppDate}
                    appointmentTime={item.AppTime}
                    joinURL={item.join_url}
                    status={item.Status}
                    selectedTab={selectedTab}
                    onReschedule={() =>
                      handleReschedule(
                        item.Name,
                        item.ProfileImage,
                        item.client_type,
                        item.ID,
                        item.AppDate,
                        item.AppTime,
                        item.CallingMode,
                        item.Status,
                        item.duration
                      )
                    }
                    onAddNote={() =>
                      handleNotes(
                        item.Name,
                        item.ProfileImage,
                        item.client_type,
                        item.ID,
                        item.AppDate,
                        item.AppTime,
                        item.UserId,
                        item.Status
                      )
                    }
                    onPrescription={() =>
                      handlePrescription(
                        item.Name,
                        item.ProfileImage,
                        item.client_type,
                        item.ID,
                        item.AppDate,
                        item.AppTime
                      )
                    }
                    onCancel={() =>
                      handleCancelAppointment(
                        item.Name,
                        item.ProfileImage,
                        item.client_type,
                        item.ID,
                        item.AppDate,
                        item.AppTime
                      )
                    }
                  />
                </div>
              ))}
            </div>
            <div className="d-flex justify-content-end w-100">
              <Pagination
                count={totalPage}
                page={page}
                onChange={handleChangePage}
              />
            </div>
          </>
        )}
      </Grid>

      {/* Notes Dialog */}
      <Dialog
        className="Dialog Notes--Dialog"
        open={openNotesModal}
        onClose={handleNotesClose}
        sx={{
          maxWidth: "610px",
          width: "98%",
          minWidth: "580px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {isNoteAdded !== null && !isNoteAdded && (
          <div
            style={{
              width: "100%",
              height: "100%",
              background: "rgb(183 216 237 / 58%)",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "10",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        <DialogTitle>Add Notes</DialogTitle>
        <DialogContent className="Dialog--Content">
          <div className="Content--Header d-flex  align-items-center gap-2">
            <img
              src={clientData?.clientImage}
              width={45}
              style={{ borderRadius: "50%" }}
            />

            <div>
              <h6>{clientData?.clientName}</h6>
              <span>
                {clientData?.appointmentDate} | {clientData?.appointmentTime} |{" "}
                {clientData?.clientType}
              </span>
            </div>
          </div>

          <TextField
            id="outlined-multiline-static"
            label="Write Something"
            multiline
            value={note}
            rows={4}
            fullWidth
            onChange={(e) => handleNote(e)}
            sx={{ marginTop: "20px" }}
          />

          <div className="d-flex justify-content-center mt-4 mb-1">
            <Button
              variant="contained"
              className="PrimaryCTAExp"
              onClick={handlePostNote}
              disabled={note?.trim() == "" ? true : false}
            >
              Add Note
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/* Reschedule Dialog */}
      <Dialog
        className="Dialog Reschedule--Dialog"
        open={openRescheduleModal}
        onClose={handleRescheduleClose}
        sx={{
          maxWidth: "610px",
          width: "98%",
          minWidth: "580px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {isRescheduled !== null && !isRescheduled && (
          <div
            style={{
              width: "100%",
              height: "100%",
              background: "rgb(183 216 237 / 58%)",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "10",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        <DialogTitle>Reschedule Appointment </DialogTitle>
        <DialogContent className="Dialog--Content">
          <div className="Content--Header d-flex align-items-center gap-2">
            <img
              src={clientData?.clientImage}
              width={45}
              style={{ borderRadius: "50%" }}
            />

            <div>
              <h6>{clientData?.clientName}</h6>
              <span>
                {clientData?.appointmentDate} | {clientData?.appointmentTime} |{" "}
                {clientData?.clientType}
              </span>
            </div>
          </div>

          <div>
            <h4>Reschedule to</h4>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="To date"
                format="DD/MM/YYYY"
                value={
                  selectedDate ||
                  dayjs(clientData?.appointmentDate, "DD MMM YYYY")
                }
                onChange={onToDateChange}
                disablePast={true}
                sx={{
                  width: "100%",
                  marginBottom: "8px",
                  backgroundColor: "#FFF",
                }}
              />
            </LocalizationProvider>
          </div>

          <div>
            {/* <div className="d-flex gap-1 SlotTiming">
              <p className={`${!checked ? "SelectedSlot" : ""}`}>
                Morning Slots
              </p>
              <Switch
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
              <p className={`${checked ? "SelectedSlot" : ""}`}>
                Evening Slots
              </p>
            </div> */}

            <Grid
              container
              gap={2}
              sx={{ width: "100%", margin: "0" }}
              className="Timings"
            >
              {/* .sort((a,b) => a.slot_id - b.slot_id) */}
              {slotsList?.length > 0 ? (
                slotsList?.map((item) => (
                  <Grid
                    item
                    xs={2}
                    key={item.slot}
                    className={`AvailableSlot ${
                      item.id == clientData?.slotID ? "SelectedSlot" : ""
                    }`}
                    onClick={() => handleSlotChange(item)}
                  >
                    <p>{item.slot.split(" - ")[0]}</p>
                  </Grid>
                ))
              ) : (
                <p className="mx-auto" style={{ fontWeight: "500" }}>
                  No slots available for selected dates
                </p>
              )}
            </Grid>
          </div>

          <Button
            className="PrimaryCTAExp"
            variant="contained"
            disableElevation
            sx={{ margin: "62px auto 20px auto" }}
            onClick={handleRescheduleAppointment}
            disabled={clientData.slotID === 0 ? true : false}
          >
            Confirm and Reschedule
          </Button>
        </DialogContent>
      </Dialog>

      {/* Precriptions Dialog */}
      <Dialog
        className="Dialog Prescription--Dialog"
        open={openPrescriptionModal}
        onClose={handlePrescriptionClose}
        sx={{
          maxWidth: "610px",
          width: "98%",
          minWidth: "580px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {isPrescriptionAdded !== null && !isPrescriptionAdded && (
          <div
            style={{
              width: "100%",
              height: "100%",
              background: "rgb(183 216 237 / 58%)",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "10",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        <DialogTitle>Add Prescription</DialogTitle>
        <DialogContent className="Dialog--Content">
          <div className="Content--Header d-flex align-items-center gap-2">
            <img
              src={clientData?.clientImage}
              width={45}
              style={{ borderRadius: "50%" }}
            />

            <div>
              <h6>{clientData?.clientName}</h6>
              <span>
                {clientData?.appointmentDate} | {clientData?.appointmentTime} |{" "}
                {clientData?.clientType}
              </span>
            </div>
          </div>

          <TextField
            id="outlined-multiline-static"
            label="Prescription Name"
            value={prescriptionName}
            onChange={handlePrescriptionName}
            fullWidth
            sx={{ marginTop: "20px" }}
          />
          <TextField
            id="outlined-multiline-static"
            label="Prescription notes"
            value={prescriptionNote}
            onChange={handlePrescriptionNote}
            multiline
            rows={4}
            fullWidth
            sx={{ marginTop: "10px", marginBottom: "10px" }}
          />
          <div
            className="upload-button"
            style={{ marginTop: "0", height: "100%" }}
          >
            <label
              htmlFor="file-upload"
              className="upload-button-label"
              style={{ height: "100%", fontSize: "16px" }}
            >
              <FileUploadOutlinedIcon className="upload-button-icon" />
              Upload
            </label>
            {isFileLoading !== null && isFileLoading ? (
              <div className="d-flex align-items-center mx-auto">
                <CircularProgress
                  sx={{ width: "20px !important", height: "20px !important" }}
                />
              </div>
            ) : (
              <>
                <input
                  id="file-upload"
                  type="file"
                  multiple
                  onChange={handleFileSelect}
                  className="upload-button-input"
                />
                {selectedFiles && (
                  <div className="selected-files">
                    <div className="file-name">{selectedFiles}</div>
                  </div>
                )}
              </>
            )}
          </div>

          <Button
            className="PrimaryCTAExp"
            variant="contained"
            disableElevation
            sx={{ margin: "30px auto 20px auto" }}
            onClick={handlePostPrescription}
            disabled={
              prescriptionName == "" || selectedFiles === "" ? true : false
            }
          >
            Add Prescription
          </Button>
        </DialogContent>
      </Dialog>

      {/* Cancel Dialog */}
      <Dialog
        className="Dialog Cancel--Dialog"
        open={openCancelConfirmationModal}
        onClose={handleCancelConfirmationClose}
        sx={{
          maxWidth: "610px",
          width: "98%",
          minWidth: "580px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        {isCancelled !== null && !isCancelled && (
          <div
            style={{
              width: "100%",
              height: "100%",
              background: "rgb(183 216 237 / 58%)",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: "10",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        <DialogContent className="Dialog--Content">
          <WarningRoundedIcon />
          <h6>
            Are you Sure you want to cancel this Appointment? This action cannot
            be undone
          </h6>
          <div className="Content--Header d-flex align-items-center gap-2">
            <img
              src={clientData?.clientImage}
              width={45}
              style={{ borderRadius: "50%" }}
            />

            <div>
              <h6>{clientData?.clientName}</h6>
              <span>
                {clientData?.appointmentDate} | {clientData?.appointmentTime} |{" "}
                {clientData?.clientType}
              </span>
            </div>
          </div>

          <div className="CTA--Container d-flex align-items-center gap-3 mt-3">
            <Button
              sx={{
                backgroundColor: "#F5F5F5",
                color: "#000",
                height: "50px",
                padding: "14px 24px",
                borderRadius: "10px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#F5F5F5",
                  color: "#000",
                },
              }}
              className=""
              variant="contained"
              disableElevation
              onClick={handleCancelConfirmationClose}
            >
              No, Take me back
            </Button>

            <Button
              className="PrimaryCTAExp"
              variant="contained"
              disableElevation
              onClick={handleCancel}
            >
              Yes, Cancel it
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/* Snackbar */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AppointmentDefaultView;
