import React, { useState } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  IconButton,
  List,
  ListItem,
  ListItemText,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
  DialogContentText,
  Box,
  RadioGroup,
  Radio,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import { useAuth } from "../../../auth";
import { Calendar, utils } from "@hassanmojab/react-modern-calendar-datepicker";
import "@hassanmojab/react-modern-calendar-datepicker/lib/DatePicker.css";
import DeleteIcon from "@mui/icons-material/Delete";
import { AirplaneTicketOutlined } from "@mui/icons-material";
import { useSnackbar } from "notistack";
const TimezoneAndDateOverride = ({ holidayModes }) => {
  // State for timezone
  const [timezone, setTimezone] = useState("UTC");
  const { enqueueSnackbar } = useSnackbar();
  // Separate state for single day and date range
  const [selectedSingleDate, setSelectedSingleDate] = useState(null);
  const [selectedRangeDates, setSelectedRangeDates] = useState({
    from: null,
    to: null,
  });
  const { currentUser } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [selectionMode, setSelectionMode] = useState("range"); // 'single' or 'range'
  const [isLoading, setIsLoading] = useState(false);
  // Timezone options
  const timezones = [
    "Pacific/Midway",
    "Pacific/Honolulu",
    "America/Juneau",
    "America/Los_Angeles",
    "America/Tijuana",
    "America/Denver",
    "America/Phoenix",
    "America/Chihuahua",
    "America/Mazatlan",
    "America/Chicago",
    "America/Regina",
    "America/Mexico_City",
    "America/Monterrey",
    "America/Guatemala",
    "America/New_York",
    "America/Indiana/Indianapolis",
    "America/Bogota",
    "America/Lima",
    "America/Halifax",
    "America/Caracas",
    "America/La_Paz",
    "America/Santiago",
    "America/St_Johns",
    "America/Sao_Paulo",
    "America/Argentina/Buenos_Aires",
    "America/Guyana",
    "Atlantic/Azores",
    "Atlantic/Cape_Verde",
    "Europe/London",
    "Europe/Lisbon",
    "Europe/Dublin",
    "Europe/Amsterdam",
    "Europe/Belgrade",
    "Europe/Berlin",
    "Europe/Bratislava",
    "Europe/Brussels",
    "Europe/Budapest",
    "Europe/Copenhagen",
    "Europe/Ljubljana",
    "Europe/Madrid",
    "Europe/Paris",
    "Europe/Prague",
    "Europe/Rome",
    "Europe/Sarajevo",
    "Europe/Skopje",
    "Europe/Stockholm",
    "Europe/Vienna",
    "Europe/Warsaw",
    "Europe/Zagreb",
    "Europe/Athens",
    "Europe/Bucharest",
    "Africa/Cairo",
    "Africa/Harare",
    "Europe/Helsinki",
    "Europe/Istanbul",
    "Asia/Jerusalem",
    "Europe/Minsk",
    "Africa/Windhoek",
    "Asia/Kuwait",
    "Asia/Riyadh",
    "Africa/Nairobi",
    "Asia/Baghdad",
    "Asia/Tehran",
    "Asia/Muscat",
    "Asia/Baku",
    "Asia/Tbilisi",
    "Asia/Yerevan",
    "Asia/Kabul",
    "Asia/Yekaterinburg",
    "Asia/Karachi",
    "Asia/Tashkent",
    "Asia/Kolkata",
    "Asia/Kathmandu",
    "Asia/Dhaka",
    "Asia/Colombo",
    "Asia/Almaty",
    "Asia/Novosibirsk",
    "Asia/Rangoon",
    "Asia/Bangkok",
    "Asia/Jakarta",
    "Asia/Krasnoyarsk",
    "Asia/Shanghai",
    "Asia/Chongqing",
    "Asia/Hong_Kong",
    "Asia/Urumqi",
    "Asia/Kuala_Lumpur",
    "Asia/Singapore",
    "Asia/Taipei",
    "Australia/Perth",
    "Asia/Irkutsk",
    "Asia/Ulaanbaatar",
    "Asia/Seoul",
    "Asia/Tokyo",
    "Asia/Yakutsk",
    "Australia/Darwin",
    "Australia/Adelaide",
    "Australia/Sydney",
    "Australia/Brisbane",
    "Australia/Hobart",
    "Asia/Vladivostok",
    "Pacific/Guam",
    "Asia/Magadan",
    "Pacific/Fiji",
    "Asia/Kamchatka",
    "Pacific/Auckland",
    "Pacific/Tongatapu",
  ];

  const handleTimezoneChange = (event) => {
    setTimezone(event.target.value);
  };

  const handleSingleDateChange = (date) => {
    setSelectedSingleDate(date);
  };

  const handleRangeDateChange = (range) => {
    setSelectedRangeDates(range);
  };

  const handleDeleteSingleDate = () => {
    setSelectedSingleDate(null);
  };

  const handleDeleteRange = () => {
    setSelectedRangeDates({ from: null, to: null });
  };

  const handleOpenDialog = () => {
    setIsOpen(true);
  };

  const handleCloseDialog = () => {
    setIsOpen(false);
    setSelectedSingleDate(null);
    setSelectedRangeDates({ from: null, to: null });
  };

  const handleSelectionModeChange = (event) => {
    setSelectionMode(event.target.value);
    setSelectedSingleDate(null);
    setSelectedRangeDates({ from: null, to: null });
  };

  const handleSave = async () => {
    setIsLoading(true); // Start loading
    try {
      // Format the date based on selection mode
      const fromDate =
        selectionMode === "single"
          ? `${selectedSingleDate?.year}/${String(
              selectedSingleDate?.month
            ).padStart(2, "0")}/${String(selectedSingleDate?.day).padStart(
              2,
              "0"
            )}`
          : `${selectedRangeDates.from?.year}/${String(
              selectedRangeDates.from?.month
            ).padStart(2, "0")}/${String(selectedRangeDates.from?.day).padStart(
              2,
              "0"
            )}`;

      const toDate =
        selectionMode === "single"
          ? `${selectedSingleDate?.year}/${String(
              selectedSingleDate?.month
            ).padStart(2, "0")}/${String(selectedSingleDate?.day).padStart(
              2,
              "0"
            )}`
          : `${selectedRangeDates.to?.year}/${String(
              selectedRangeDates.to?.month
            ).padStart(2, "0")}/${String(selectedRangeDates.to?.day).padStart(
              2,
              "0"
            )}`;

      // Validate dates
      if (!fromDate || !toDate) {
        throw new Error("Please select valid dates");
      }

      const response = await fetch(
        "https://us.uwc.world/new-uwc/expert/dashboard/holiday",
        {
          method: "POST",
          headers: {
            accept: "*/*",
            "accept-language": "en-US,en;q=0.9",
            authorization:
              "Bearer 54285c5b11961dc2d94688253ea7ce60069dcde9210482d751c322aee197cd3578838",
            "content-type": "application/json",
            "x-api-key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
          },
          body: JSON.stringify({
            expert_id: currentUser?.UserId,
            from_date: fromDate,
            to_date: toDate,
          }),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      handleCloseDialog(); // Close dialog on success
      enqueueSnackbar("Holiday mode set successfully, reload the page", {
        variant: "success",
      });
      window.location.reload();
    } catch (error) {
      console.error("Error setting holiday mode:", error);
      enqueueSnackbar("Error setting holiday mode", {
        variant: "error",
      });
    } finally {
      setIsLoading(false); // Stop loading regardless of success/failure
    }
  };

  // Function to transform holiday dates into the format needed for the calendar
  const getHolidayDates = () => {
    if (!holidayModes) return [];

    const holidayDates = [];
    holidayModes.forEach((holiday) => {
      const startDate = new Date(holiday.start_date);
      const endDate = new Date(holiday.end_date);

      // Loop through dates between start and end
      for (
        let date = new Date(startDate);
        date <= endDate;
        date.setDate(date.getDate() + 1)
      ) {
        holidayDates.push({
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate(),
          className: "holiday-date",
        });
      }
    });
    return holidayDates;
  };

  // Add this function to get disabled dates
  const getDisabledDates = () => {
    if (!holidayModes) return [];
    
    const disabledDates = [];
    holidayModes.forEach(holiday => {
      const startDate = new Date(holiday.start_date);
      const endDate = new Date(holiday.end_date);
      
      // Loop through dates between start and end
      for (let date = new Date(startDate); date <= endDate; date.setDate(date.getDate() + 1)) {
        disabledDates.push({
          year: date.getFullYear(),
          month: date.getMonth() + 1,
          day: date.getDate(),
        });
      }
    });
    return disabledDates;
  };

  // Custom styles for the calendar
  const customStyles = `
    .holiday-date {
      background-color: rgba(115, 88, 155, 0.2) !important;
      border-radius: 50%;
      color: rgb(155, 89, 88) !important;
      font-weight: bold;
      opacity: 0.7;
      pointer-events: none !important;
      cursor: not-allowed !important;
    }
    
    .Calendar__day.-disabled {
      color: #ccc !important;
      cursor: not-allowed !important;
      background-color: #f5f5f5 !important;
    }
    
    .Calendar__day.-disabled:hover {
      background-color: #f5f5f5 !important;
      cursor: not-allowed !important;
    }
    
    .Calendar__day.holiday-date.-disabled {
      background-color: rgba(115, 88, 155, 0.1) !important;
      color: #ff2929 !important;
    }
  `;

  // Function to check if a date is disabled
  const isDateDisabled = (date) => {
    return getDisabledDates().some(
      (disabled) =>
        disabled.year === date.year &&
        disabled.month === date.month &&
        disabled.day === date.day
    );
  };

  return (
    <Box sx={{ padding: "20px" }}>
      <style>{customStyles}</style>

      {/* Heading for Timezone */}
      {/* <Typography variant="h6" gutterBottom>
        Select Timezone
      </Typography> */}

      {/* Timezone Selector */}
      {/* <FormControl size="small" sx={{ width: "250px", mb: 3 }}>
        <InputLabel>Timezone</InputLabel>
        <Select
          value={timezone}
          onChange={handleTimezoneChange}
          label="Timezone"
        >
          {timezones.map((tz) => (
            <MenuItem key={tz} value={tz}>
              {tz}
            </MenuItem>
          ))}
        </Select>
      </FormControl> */}

      {/* Holiday Mode Button */}
      <Button
        className="HolidayMode PrimaryCTAExp"
        onClick={handleOpenDialog}
        sx={{ mb: 3 }}
        startIcon={<AirplaneTicketOutlined />}
      >
        Holiday Mode
      </Button>

      {/* Dialog for Date Range Picker */}
      <Dialog
        open={isOpen}
        onClose={handleCloseDialog}
        aria-labelledby="holiday-mode-dialog"
        aria-describedby="holiday-mode-description"
      >
        <DialogContent sx={{ minWidth: "600px", width: "100%" }}>
          <DialogContentText
            id="holiday-mode-description"
            sx={{ color: "#000", fontSize: "1rem", fontWeight: "400", mb: 3 }}
          >
            Select the date for your holiday.
          </DialogContentText>

          {/* Selection Mode (Single Day or Date Range) */}
          <FormControl>
            <RadioGroup
              row
              value={selectionMode}
              onChange={handleSelectionModeChange}
              sx={{ mb: 3 }}
            >
              <FormControlLabel
                value="single"
                control={
                  <Radio
                    sx={{
                      color: "var(--primary-base-color)",
                      "&.Mui-checked": {
                        color: "var(--primary-base-color)",
                      },
                    }}
                  />
                }
                label="Single Day"
              />
              <FormControlLabel
                value="range"
                control={
                  <Radio
                    sx={{
                      color: "var(--primary-base-color)",
                      "&.Mui-checked": {
                        color: "var(--primary-base-color)",
                      },
                    }}
                  />
                }
                label="Date Range"
              />
            </RadioGroup>
          </FormControl>

          {/* Calendar for Date Picker */}
          <Box sx={{ display: "flex" }}>
            {selectionMode === "single" ? (
              <Calendar
                minimumDate={utils().getToday()}
                value={selectedSingleDate}
                onChange={handleSingleDateChange}
                colorPrimary="var(--primary-base-color)"
                colorPrimaryLight="var(--primary-base-opacity30)"
                shouldHighlightWeekends
                customDaysClassName={getHolidayDates()}
                disabledDays={getDisabledDates()}
                onDisabledDayError={() => {
                  enqueueSnackbar("This date is already marked as a holiday", { 
                    variant: "warning" 
                  });
                }}
                renderFooter={() => (
                  <span
                    style={{
                      padding: "10px",
                      fontSize: "12px",
                      color: "#999",
                    }}
                  >
                    Select a single date for your holiday.
                    <br />
                    Highlighted dates are existing holidays and cannot be selected.
                  </span>
                )}
              />
            ) : (
              <Calendar
                minimumDate={utils().getToday()}
                value={selectedRangeDates}
                onChange={handleRangeDateChange}
                colorPrimary="var(--primary-base-color)"
                colorPrimaryLight="var(--primary-base-opacity30)"
                shouldHighlightWeekends
                isRangeSelector
                customDaysClassName={getHolidayDates()}
                disabledDays={getDisabledDates()}
                onDisabledDayError={() => {
                  enqueueSnackbar("Selected range includes existing holiday dates", { 
                    variant: "warning" 
                  });
                }}
                renderFooter={() => (
                  <span
                    style={{
                      padding: "10px",
                      fontSize: "12px",
                      color: "#999",
                    }}
                  >
                    Select a start and end date for your holiday.
                    <br />
                    Highlighted dates are existing holidays and cannot be selected.
                  </span>
                )}
              />
            )}

            {/* Display Selected Date/Date Range with Delete Option */}
            <Box sx={{ ml: 2 }}>
              <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                Selected {selectionMode === "range" ? "Date Range" : "Date"}
              </Typography>

              {selectionMode === "range" ? (
                selectedRangeDates.from && selectedRangeDates.to ? (
                  <List>
                    <ListItem divider>
                      <ListItemText
                        primary={`From: ${selectedRangeDates.from.day}/${selectedRangeDates.from.month}/${selectedRangeDates.from.year}`}
                        secondary={`To: ${selectedRangeDates.to.day}/${selectedRangeDates.to.month}/${selectedRangeDates.to.year}`}
                      />
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={handleDeleteRange}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  </List>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No dates selected.
                  </Typography>
                )
              ) : selectedSingleDate ? (
                <List>
                  <ListItem divider>
                    <ListItemText
                      primary={`Selected Date: ${selectedSingleDate.day}/${selectedSingleDate.month}/${selectedSingleDate.year}`}
                    />
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={handleDeleteSingleDate}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                </List>
              ) : (
                <Typography variant="body2" color="textSecondary">
                  No date selected.
                </Typography>
              )}
            </Box>
          </Box>
        </DialogContent>

        {/* Actions */}
        <DialogActions>
          <Button
            sx={{
              borderRadius: "20px", // Rounded but not too much
              bgcolor: "#f5f5f5",
              color: "#414141",
              fontSize: "14px", // 14px as in the default theme
              textTransform: "capitalize",
              "&:hover": {
                background: "#f5f5f5",
              },
              padding: "10px 20px",
            }}
            className=" rounded-3"
            onClick={handleCloseDialog}
          >
            Cancel
          </Button>

          <Button
            className=" rounded-3"
            sx={{
              borderRadius: "20px",
              bgcolor: "#73589b",
              color: "white",
              fontSize: "14px",
              textTransform: "capitalize",
              "&:hover": {
                background: "#73589b",
              },
              padding: "10px 20px",
              minWidth: "100px", // Ensure button doesn't shrink when showing loader
            }}
            disableElevation
            onClick={handleSave}
            disabled={isLoading} // Disable button while loading
          >
            {isLoading ? (
              <CircularProgress size={24} sx={{ color: "white" }} />
            ) : (
              "Save"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TimezoneAndDateOverride;
