import { useState } from "react";
import moment from "moment";
import {
  Card,
  CardContent,
  Chip,
  Grid,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

const AppointmentTabItem = ({
  ID,
  Status,
  AppDate,
  AppTime,
  duration,
  notes,
}) => {
  return (
    <TableRow hover key={ID}>
      {/* Status Column */}
      <TableCell>
        <Chip
          label={
            Status === "Upcoming"
              ? "Upcoming"
              : Status === "Cancelled"
              ? "Not Completed"
              : "Completed"
          }
          style={{
            color:
              Status === "Upcoming"
                ? "#73589b"
                : Status === "Cancelled"
                ? "#D92D20"
                : "#079455",
            backgroundColor:
              Status === "Upcoming"
                ? "#73589b85"
                : Status === "Cancelled"
                ? "#FEE4E2"
                : "#DCFAE6",
          }}
        />
      </TableCell>

      {/* Date Column */}
      <TableCell>
        <Typography variant="body2">{AppDate}</Typography>
      </TableCell>

      {/* Time Column */}
      <TableCell>
        <Typography variant="body2">{AppTime}</Typography>
      </TableCell>

      {/* Duration Column */}
      <TableCell>
        <Typography variant="body2">{duration}</Typography>
      </TableCell>
    </TableRow>
  );
};

export default AppointmentTabItem;
