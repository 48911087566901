import React, { useState, useEffect } from "react";
import { useAuth } from "../../../auth";
import { Skeleton, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSnackbar } from "notistack";

const HolidaysList = () => {
  const [holidays, setHolidays] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    fetchHolidays();
  }, [currentUser?.UserId]);

  const fetchHolidays = async () => {
    try {
      const response = await fetch(
        "https://us.uwc.world/new-uwc/expert/dashboard/get-holiday-modes",
        {
          method: "POST",
          headers: {
            "content-type": "application/json",
            "x-api-key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
          },
          body: JSON.stringify({
            expert_id: currentUser?.UserId, // You might want to make this dynamic based on the logged-in expert
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch holidays");
      }

      const data = await response.json();
      if (data?.code === 200) {
        setHolidays(data?.data?.holidays);
      } else {
        setHolidays([]);
      }
    } catch (error) {
      console.error("Error fetching holidays:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteHoliday = async (holidayId) => {
    try {
      const response = await fetch(
        "https://us.uwc.world/new-uwc/expert/dashboard/cancel-holiday",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-API-Key": "76d802e426a2cb28f3760c8c8f669983f67ed775",
          },
          body: JSON.stringify({
            id: holidayId,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to delete holiday");
      }

      // Remove the deleted holiday from the state
      setHolidays((prevHolidays) =>
        prevHolidays.filter((holiday) => holiday.id !== holidayId)
      );
      enqueueSnackbar("Holiday mode deleted successfully, reload the page", {
        variant: "success",
      });
    } catch (error) {
      console.error("Error deleting holiday:", error);
      enqueueSnackbar("Failed to delete holiday", {
        variant: "error",
      });
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const getHolidayMessage = (startDate, endDate) => {
    if (startDate === endDate) {
      return (
        <>
          Holiday Active on{" "}
          <span
            style={{
              fontWeight: "bold",
              color: "#ff2929",
            }}
          >
            {formatDate(startDate)}
          </span>
        </>
      );
    }
    return (
      <>
        Holiday active from{" "}
        <span
          style={{
            fontWeight: "bold",
            color: "#ff2929",
          }}
        >
          {formatDate(startDate)}
        </span>{" "}
        to{" "}
        <span
          style={{
            fontWeight: "bold",
            color: "#ff2929",
          }}
        >
          {formatDate(endDate)}
        </span>
      </>
    );
  };

  return (
    <div className=" border rounded-4" style={{ padding: "20px" }}>
      <div className="my-3 ScheduleDashboard">
        <div className="ScheduleDashboard--Header d-flex">
          <h2>Holidays List</h2>
        </div>
      </div>
      {loading ? (
        // Skeleton loader
        <>
          {[1, 2, 3].map((item) => (
            <div
              key={item}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "12px",
                marginBottom: "8px",
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
              }}
            >
              <Skeleton variant="text" width="80%" height={24} />
              <Skeleton variant="circular" width={24} height={24} />
            </div>
          ))}
        </>
      ) : (
        <div>
          {/* Render your holidays data here */}
          {holidays.map((holiday, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "12px",
                marginBottom: "8px",
                backgroundColor: "#f5f5f5",
                borderRadius: "8px",
              }}
            >
              <p style={{ margin: 0 }}>
                {getHolidayMessage(holiday.start_date, holiday.end_date)}
              </p>
              <Tooltip title="Delete Holiday" placement="top">
                <IconButton
                  aria-label="delete holiday"
                  onClick={() => deleteHoliday(holiday.id)}
                  size="small"
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default HolidaysList;
